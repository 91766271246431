import React, { useState } from "react";

import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Chip, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';

import { FileDrop } from 'react-file-drop';

import ImportIcon from '@mui/icons-material/Upload';

import useMediaQuery from '@mui/material/useMediaQuery';
import Information from "../../Crm/TabButtons/Information";


export default function ResponsiveImportButton({ className, loading, isDisabled, onClickFn, shouldRenderHiddenInput, onChangeFn, is_financial_market_company }) {
  const isSmallScreen = useMediaQuery('(max-width: 60rem)');

  const [selectedSeparator, setSelectedSeparator] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [separatorLabel, setSeparatorLabel] = useState("Personalizado");


  return (
    <>
      <>
        <LoadingButton
          className={className}
          variant="contained"
          component="label"
          color="success"
          loading={loading}
          loadingPosition="start"
          disabled={isDisabled}
          onClick={(...params) => {
            onClickFn(...params);
            setShowDialog(true);
          }}
        >
          Importar em massa
        </LoadingButton>

        {showDialog && <Dialog open={true} onClose={() => {
          setShowDialog(false);
          console.log('close: ', showDialog);
        }}
          BackdropClick={false}
          fullWidth
          maxWidth='md'
          disableEnforceFocus>
          <DialogTitle size="small">Selecione o caracter separador ou digite o separador desejado</DialogTitle>
          <DialogContent>
            <>  <Box sx={{display: 'flex', gap:'.5rem'}}>
              <Button sx={{ width: '2.5rem', minWidth: 'unset' }} variant={selectedSeparator === ',' ? 'contained' : ''} onClick={() => { setSelectedSeparator(','); setSeparatorLabel(',') }}>,</Button>
              <Button sx={{ width: '2.5rem', minWidth: 'unset' }} variant={selectedSeparator === ';' ? 'contained' : ''} onClick={() => { setSelectedSeparator(';'); setSeparatorLabel(';') }}>;</Button>
              <Button sx={{ width: '2.5rem', minWidth: 'unset' }} variant={selectedSeparator === '|' ? 'contained' : ''} onClick={() => { setSelectedSeparator('|'); setSeparatorLabel('|') }}>|</Button>
              <TextField
                variant="outlined"
                size="small"
                value={selectedSeparator}
                sx={{ maxWidth: '2.5rem'}}
                inputProps={{
                  maxLength: 1,
                  style: {textAlign: 'center'}
                }}
                onChange={e => setSelectedSeparator(e.target.value)}
              />
            </Box>
              <div className="file-drop-container" style={{ marginTop: "1rem" }}>
                <FileDrop
                  onDrop={(files, event) => { setFile(files[0]); event.target.value = null }}
                >
                  {file ?
                    <Chip label={file.name} onDelete={() => { setFile(null) }} /> :
                    <span>Arraste e solte para inserir o anexo.
                    </span>
                  }
                  <span>ou</span>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    component="label"
                    loading={false}
                    loadingPosition="start"
                    startIcon={<ImportIcon />}
                    disabled={false}
                    onClick={() => { }}
                  >
                    Selecione
                    <input
                      hidden
                      accept=".csv,.CSV"
                      type="file"
                      onChange={(e) => { setFile(e.target.files[0]); e.target.value = null }}
                    />
                  </LoadingButton>
                </FileDrop>
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setShowDialog(false) }}>Cancelar</Button>
            <Button
              disabled={!selectedSeparator || selectedSeparator.length === 0 || selectedSeparator.value == ("Personalizado") || !file}
              onClick={() => {
                onChangeFn(file, selectedSeparator);
                setShowDialog(false);
              }}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        }
        <Information
          is_financial_market_company={is_financial_market_company}
        />
      </>
    </>
  )
}
