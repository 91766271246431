import React from 'react'
import { NumericFormat } from 'react-number-format';


export const NumericFormatInput = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const {onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            prefix={'R$ '}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            valueIsNumericString
            onValueChange={(values) => {
                onChange({
                  target: {
                    name: props.name,
                    value: values.value || 0,
                  },
                });
              }}
        />
    )
}
)