import React, { useEffect, useState, useCallback, useMemo } from 'react'

import { Box, Alert, AlertTitle, Chip } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

import { ConnectButton } from "360dialog-connect-button";

import AddIcon from '@mui/icons-material/Add';

import useNotification from "../../Notification";
import Loading from '../../Loading';
import api from '../../../api';

import { FacebookPlugin } from '../Instagram/index.js';

import { getSession } from '../../../auth.js';

import './styles.css'

const Channels = ({ enterprise }) => {

    const partnerId = 'DrXGjiPA';
    const user = getSession();

	const [loading, setLoading] = useState(false);
	const [waiting, setWaiting] = useState(false);
	const [numbers, setNumbers] = useState([]);
	const [reload, setReload] = useState(false)
	const [connectedPage, setConnectedPage]= useState(null)

	const reloadState = () => {setTimeout(()=>setReload(p=>!p), 500)}

    const handleConnectCallback = (callbackObject) => {
        const clientId = callbackObject.client;
        const url = `api/enterprises/${enterprise.id}/360dialog_channels/`;

        callbackObject.channels.forEach((channel) => {
            api.post(url, { channel_id: channel }, { timeout: 0 })
            .then((res) => {
                if (res.status == 201) {
                    window.location.href = '/settings'
                    return;
                }
                if (res.status == 400) {
                    // TODO: callback is being executed twice, within
                    // 360dialog's window and again when it returns here
                    // this should be separated, otherwise it will throw
                    // a 400 BAD_REQUEST here
                    return;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });
        });
    }

	const getNumbers = useCallback(async () => {
		setLoading(true)
	    try {
	        const [numbersRes, metaNumbersRes] = await Promise.all([
	            api.get(`api/enterprises/${enterprise.id}/360dialog_channels/`),
	            api.get(`api/enterprises/${enterprise.id}/whatsapp/`)
	        ])

	        if (numbersRes.status !== 200 || metaNumbersRes.status !== 200) {
	            useNotification(
	                "Ops!",
	                "Não foi possível encontrar os números de WhatsApp.",
	                "danger"
	            )
	            return
	        }

	        const combinedNumbers = [...numbersRes.data, ...metaNumbersRes.data]
	        setNumbers(combinedNumbers)
	    } catch (error) {
	        useNotification(
	            "Ops!",
	            "Ocorreu um erro ao buscar os números de WhatsApp.",
	            "danger"
	        )
	    } finally {
	        setLoading(false)
	    }
	}, [])

	useEffect(() => {
		getNumbers()
	}, [])

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		// Wait while API key is being generated
		setWaiting(queryParams.has('client') || queryParams.has('channels'))
	}, [])


	const facebookPlugin = useMemo(() => {
		return (<FacebookPlugin reloadState={reloadState} reload={reload} setConnectedPage={setConnectedPage} app="WHATSAPP"/>)
	}, [reload])

	const launchWhatsAppSignup = () => {
	    // Launch Facebook login
	    FB.login(function (response) {
			if (response.authResponse) {
				const code = response.authResponse.code;
				if (code) {
					const payload = {
						'user_token' : connectedPage['authResponse']['accessToken'],
						'code'       : code
					}
					setLoading(true)
					api.post(`api/enterprises/${enterprise.id}/whatsapp/`, payload, { timeout: 0 }).then((res) => {
						setLoading(false)
						if (res.status !== 201) {
							useNotification(
					            'Ops!',
					            'Algo deu errado, tente novamente.',
					            'danger'
					        );
							return
						}
						setNumbers(res.data)
					})
				}
			} else {
				console.log('User cancelled login or did not fully authorize.');
			}
		}, {
			config_id: process.env.REACT_APP_WHATSAPP_APP_CONFIG_ID ?? '788098360113528',
			response_type: 'code',
			override_default_response_type: true,
			extras: {
				setup: {
					"feature": "whatsapp_embedded_signup",
					"sessionInfoVersion": 2
				}
			}
		});
	}

	return (
        <Box className="input-settings settings-body">
        	<Loading loading={loading}/>
        	<Loading loading={! loading && waiting} message="Gerando chave de API..."/>
        	<Alert className="telegram-info" severity="info">
				<AlertTitle className="push-bottom">
					Para incluir uma conta WhatsApp no kwik, conectada diretamente com a Meta, é necessário:
				</AlertTitle>
				<Box>
					<p>Uma conta empresarial no facebook.</p>
					<p>Ceder permissões ao Kwik, clicando no botão abaixo: {facebookPlugin}</p>
				</Box>
				<Box className="push-s-top">
					Você pode adicionar seu número clicando em um dos botão abaixo.
				</Box>
			</Alert>
        	{numbers.length !== 0 &&
        		<>
		        	<Box className="category-title push-bottom">
		                Números
		            </Box>
		            <Box>
			            <table>
			            	<tbody>
			            		{numbers.map((item, index) => (
					            	<tr>
					            		<td style={{ padding: ".25rem .5rem" }}>{item.phone_number}</td>
					            		<td style={{ padding: ".25rem .5rem" }}>{item.phone_name}</td>
					            		<td style={{ padding: ".25rem .5rem" }}>
					            			{"info_360dialog" in item &&
				                                <Chip className="number-status" 
					                                label={item.info_360dialog?.account_mode == "live" ? "Ativo" : "Inativo" }
					                                color={item.info_360dialog?.account_mode == "live" ? "success" : "error" }
					                                size="small"
					                            />
			                            	}
					            		</td>
					            	</tr>
					            ))}
				            </tbody>
			            </table>
			        </Box>
		        </>
        	}
        	{numbers.length < enterprise.max_numbers &&
        		<Box style={{ marginTop: "1rem" }}>
        			{enterprise.abstraction_layer &&
	        			<LoadingButton 
		    				startIcon={<AddIcon />} 
			        		variant="contained"
			        		onClick={() => launchWhatsAppSignup()}
			        	>
			        		Adicionar
			        	</LoadingButton>
        			}
        			{! enterprise.abstraction_layer &&
			        	<ConnectButton partnerId={partnerId}
			                callback={handleConnectCallback}
			                queryParameters={{
			                    email        : user.username,
			                    name         : `${user.first_name} ${user.last_name}`,
			                    redirect_url : window.location.href
			                }}
			                className="onboarding-connect-button"
			                label={(<><AddIcon /> Adicionar</>)}
			            />
			        }
		        </Box>
        	}
        	{numbers.length >= enterprise.max_numbers &&
        		<Box className="whatsapp-mumbers-info">
		        	<LoadingButton startIcon={<AddIcon />} 
		        		variant="contained" 
		        		disabled={true}
		        	>Adicionar</LoadingButton>
		        	<Alert severity="info">
		        		Entre em contato com seu gerente de contas para contratar mais uma linha. Caso já tenha adquirido a linha, fale com nosso suporte para liberação.
		        	</Alert>
		        </Box>
        	}
        </Box>
	)
}

export default Channels
