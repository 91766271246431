import React, { useReducer, useState, useEffect } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    TextField,
    FormControlLabel
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import smsProviders, { RenderProvider } from './providers'
import { lockedReducer } from '../../../../utils/defaultReducer'

import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import Locale from 'react-phone-number-input/locale/pt-BR.json'

import style from './styles.module.css'

const copyObject = (object) => {
    return JSON.parse(JSON.stringify(object))
}

export default function AddBotDialog({ data, handleClose, submit }) {

    const [state, setState] = useReducer(lockedReducer, {
        id: 0,
        phone_number: '',
        configuration: {
            provider: 0
        },
        ...data,
    })

    const [useString, setUseString] = useState(false)

    const providers = copyObject(smsProviders)

    useEffect(()=>{
        if (data.id && !(/^\d+$|^\+\d+$/.test(state.phone_number)))
            setUseString(true)
    }, [])

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className="group-flexbox">
                    {data.id ? 'Editar' : 'Adicionar'} Sms
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent className="add-bot-dialog-content">
                    <Box className={style.flexbox}>
                        <Box sx={{flex:1}}>
                        {!useString ?
                            <PhoneInput
                                defaultCountry='BR'
                                locale={Locale.BR}
                                placeholder="Número de telefone (apenas números)"
                                limitMaxLength
                                value={state.phone_number}
                                onChange={(e) => { setState({ phone_number: e }) }}
                                disabled={state.id}
                            />
                            :
                            <TextField
                                placeholder='Texto'
                                label='Nome do telefone'
                                value={state.phone_number}
                                fullWidth
                                size={'small'}
                                onChange={(e) => { setState({ phone_number: e.target.value }) }}
                                disabled={data.id}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        }
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox disabled={data.id} checked={useString}  onChange={(e) => setUseString(e.target.checked)} name="gilad" />
                            }
                            label="Usar texto"
                        />
                    </Box>
                    <FormControl
                        fullWidth
                        size="small"
                        sx={{ flex: 1 }}
                    >
                        <InputLabel id="provider-label">Provedor</InputLabel>
                        <Select
                            value={state.configuration.provider}
                            onChange={(e) => setState({ configuration: { provider: e.target.value, data: providers[e.target.value] } })}
                            labelId='provider-label'
                            label='Provedor'
                        >
                            <MenuItem key={`select-0-provider`} value={0}>Selecione*</MenuItem>
                            {Object.entries(copyObject(providers)).map(([key, value], index) => (
                                <MenuItem key={`${key}-${index}-provider`} value={key}>{value.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {state.configuration.provider !== 0 && <RenderProvider parent={state} setParent={setState} />}
                </DialogContent>
                <DialogActions >
                    <Box className="user-footer">
                        <LoadingButton className="button-created" onClick={handleClose}>
                            Cancelar
                        </LoadingButton>
                        <LoadingButton className="button-created"
                            onClick={() => {
                                submit(state)
                            }}
                            disabled={state.configuration.provider === 0 || state.phone_number.length === 0}
                        >
                            Salvar
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}
