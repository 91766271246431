
import React from "react";

import { Box, AppBar, Toolbar, IconButton, Button, Alert } from "@mui/material";
import { Typography, Grid, TextField, InputAdornment, Slide } from "@mui/material";

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';

import useNotification from '../../../../Notification';
import api from '../../../../../api';
import { getSession } from "../../../../../auth";

import "./styles.css";

class AttachmentArea extends React.Component {

    constructor(props) {
        super(props);

        this.settings = {
            'image': {
                'translation': 'imagem',
                'max_size': '5MB',
                'max_bytes': 5242880
            },
            'video': {
                'translation': 'vídeo',
                'max_size': '16MB',
                'max_bytes': 16777216
            },
            'document': {
                'translation': 'documento',
                'max_size': '100MB',
                'max_bytes': 104857600
            }
        }

        this.state = {
            fileType: this.props.file.type,
            canSubmit: this.props.file.file.size <= this.settings[this.props.file.type].max_bytes
        }
    }

    handleSubmit = async () => {
        if (this.props.isActiveChatOnQueue())
            await this.props.connectActiveChat();

        const media = this.props.file;
        const file = new File([media.file], media.name);

        const chat = this.props.chat
        const queue_name = chat.queues_name ? chat.queues_name : chat.queue.name
        const channel = chat.channel ? chat.channel : chat.hash
        const connection = this.props.connections?.find(con => con.queue == queue_name)

        const formData = new FormData();
        formData.append('media', file);
        formData.append('chat_hash', this.props.activeChat.channel);
        formData.append('queue_id', this.props.activeChat.queue.id);

        let newMessage = {
            "id": 0,
            "message": 'Enviando Mídia',
            "iso_time": "--:--",
            "msg": 'SENDINGMEDIAMESSAGE',
        }

        this.props.addMessage(newMessage)

        api.post('/api/whatsapp/media/', formData, { timeout: 0 }).then(async (res) => {

            if (res.status !== 200) {
                newMessage.id = -2
                newMessage.expired = true
                newMessage.message = 'Enviou Falhou!'
                newMessage.time = Math.floor(Date.now() / 1000)
                this.props.addMessage()
            }

            if (res.status == 400) {
                if (res.data == 'Session closed') {
                    await useNotification(
                        'Sessão encerrada!',
                        'Você não pode mais enviar mensagens para esta conversa',
                        'danger'
                    );
                } else if (res.data == 'Media type not allowed') {
                    await useNotification(
                        'Arquivo inválido!',
                        'Não é permitido o envio deste tipo de mídia',
                        'danger'
                    );
                } else if (res.data == 'Media size limit exceeded') {
                    await useNotification(
                        'Arquivo inválido!',
                        'O arquivo excedeu o limite máximo permitido',
                        'danger'
                    );
                } else if (res.data == 'Media extension not allowed') {
                    await useNotification(
                        'Arquivo inválido!',
                        'Não é pertimido o envio desta extensão de mídia',
                        'danger'
                    );
                } else if (res.data == 'Sandbox environment cannot exchange media') {
                    await useNotification(
                        'Sandbox!',
                        'Não é pertimido o envio de mídia em ambiente sandbox',
                        'danger'
                    );
                } else if (res.data == "Can't send document to Instagram") {
                    await useNotification(
                        'Ops!',
                        'Não é possível enviar documentos ao Instagram',
                        'danger'
                    );
                } else if (res.data == "Invalid url returned") {
                    await useNotification(
                        'Ops!',
                        'Erro ao recuperar URL',
                        'danger'
                    );
                }
                return;
            }

            if (res.status == 404) {
                await useNotification(
                    'Chat não encontrado!',
                    'Este chat foi finalizado pelo sistema',
                    'danger'
                );
                return;
            }

            if (res.status == 200) {

                Object.entries(res.data).map(([header, value]) => {
                    newMessage[header] = value
                })

                if (!this.isSupervised(this.props.activeChat.queue))
                    newMessage['message'] = newMessage['message']

                this.props.activeChat.socket?.send(JSON.stringify(
                    {
                        'channel': this.props.activeChat.channel,
                        'message': JSON.stringify(res.data),
                        'user': -2
                    }))

                this.props.addMessage()
                this.props.scrollDown()

                if (queue_name && connection && channel && 'chatstatus' in this.props.activeChat && this.props.activeChat.chatstatus == 'QUEUE')
                    connection.send(JSON.stringify({
                        message: "ONCHAT",
                        channel: channel
                    }))
                return;
            }

            throw res;
        }).catch(async (err) => {
            await useNotification(
                'Ops!',
                'Ocorreu um erro no sistema',
                'danger'
            );
            console.error(err);
        });

        this.props.setFile({});
    }

    isSupervised = (queue) => {
        return 'settings' in this.props.activeChat.queue
            && this.props.activeChat.queue.settings
            && 'media_supervision' in this.props.activeChat.queue.settings
            && this.props.activeChat.queue.settings.media_supervision;
    }

    hasWhatsappBlock = () => {
        const filename = this.props.file?.name
        return this.props.activeChat.platform === 'WHATSAPP' && filename.substr(filename?.length-3, filename?.length) === 'xls'
    }

    render() {
        return (
            <Slide direction="up" in={this.props.file.preview !== undefined} mountOnEnter unmountOnExit>
                <Box id="attachmentArea-box-1">
                    <Box id="attachmentArea-box-2">
                        {this.props.file.type === "image" &&
                            <img src={this.props.file.preview}
                                style={{
                                    maxHeight: "45vh",
                                    maxWidth: "60vw"
                                }} />
                        }
                        {this.props.file.type === "video" &&
                            <iframe height="80%" type="video/mp4/*"
                                src={this.props.file.preview} />
                        }
                        {this.props.file.type === "document" &&
                            <Box className='document-align-master'>
                                <a target="_blank" href={this.props.file.preview}>
                                    <InsertDriveFileIcon id="insertDriveFileIcon" />
                                </a>
                                <Box id="attachmentArea-box-3">
                                    <Typography align="center">
                                        {this.props.file.name}
                                    </Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                    {!this.state.canSubmit &&
                        <Box textAlign="center" color="red" fontWeight="bold">
                            Tamanho máximo de {this.settings[this.state.fileType].translation}: {this.settings[this.state.fileType].max_size}
                        </Box>
                    }
                    {this.hasWhatsappBlock() && 
                    <Box textAlign="center" color="red" fontWeight="bold">
                        Arquivos .xls não podem ser enviados pelo canal whatsapp, salve o arquivo como .xlsx e tente novamente.
                        </Box>}
                    <Box className={'preview-select-options'}>
                        <Button onClick={() => this.props.setFile({})}>
                            Cancelar
                        </Button>
                        <Button className="send-attachment-btn"
                            variant="contained"
                            disabled={!this.state.canSubmit || this.hasWhatsappBlock()}
                            onClick={() => this.handleSubmit()}
                        >
                            Enviar
                        </Button>
                    </Box>
                </Box >
            </Slide >
        );
    }
}

export default AttachmentArea;
