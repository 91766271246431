import React, { useEffect, useState } from "react"

import { 
    Box, 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    TextField, 
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material"

import { createTheme, ThemeProvider } from "@mui/material/styles"

import { 
    DataGridPro, 
    GridToolbarContainer, 
    GridToolbarColumnsButton, 
    GridToolbarFilterButton, 
    gridClasses, 
    ptBR 
} from "@mui/x-data-grid-pro"

import { ptBR as corePtBR } from "@mui/material/locale"

import LoadingButton from "@mui/lab/LoadingButton"

import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleIcon from "@mui/icons-material/AddCircle"

import useNotification from "../Notification"
import ConfirmationDialogCaptcha from "../Dialogs/ConfimationDialogCaptcha"

import api from "../../api"

import "./styles.css"
import { NumericFormatInput } from "../../utils/masks"

const Products = () => {

    const [products, setProducts] = useState([])
    const [product, setProduct] = useState()
    const [toDeleteProduct, setToDeleteProduct] = useState()

    const theme = createTheme({
        palette: {
            primary: { main: "#21446C" },
        },
    }, ptBR, corePtBR)

    useEffect(() => {
        api.get("/api/products/").then((res) => {
            if (res.status === 200)
                setProducts(res.data)
            throw res
        }).catch((err) => {})
    }, [])

    const createProduct = (product) => {
        api.post('/api/products/', {
            channel         : product.channel,
            name            : product.name,
            description     : product.description,
            franchise_type  : product.franchise_type,
            franchise       : product.franchise,
            suggested_price : product.suggested_price,
        }).then((res) => {
            if (res.status === 201) {
                setProducts((prevProducts) => [...prevProducts, res.data])
                setProduct(null)
                useNotification(
                    "Sucesso!",
                    "Produto adicionado com sucesso.",
                    "success"
                )
                return
            }
            throw res
        }).catch ((err) => {
            useNotification(
                "Ops!",
                "Não foi possível adicionar o produto, tente novamente.",
                "danger"
            )
        })
    }

    const updateProduct = (product) => {
        api.patch(`/api/products/${product.id}/`, {
            channel         : product.channel,
            name            : product.name,
            description     : product.description,
            franchise_type  : product.franchise_type,
            franchise       : product.franchise,
            suggested_price : product.suggested_price,
        }).then((res) => {
            if (res.status === 200) {
                setProducts((prevProducts) => prevProducts.map((item) => item.id === product.id ? res.data : item))
                setProduct(null)
                useNotification(
                    "Sucesso!",
                    "Produto atualizado com sucesso.",
                    "success"
                )
                return
            }
            throw err
        }).catch((err) => {
            useNotification(
                "Ops!",
                "Não foi possível atualizar o produto, tente novamente.",
                "danger"
            )
        })
    }

    const deleteProduct = (product) => {
        api.delete(`/api/products/${product.id}/`).then((res) => {
            if (res.status === 204) {
                setProducts((prevProducts) => prevProducts.filter((item) => item.id !== product.id))
                setToDeleteProduct(null)
                useNotification(
                    "Sucesso!",
                    "Produto removido com sucesso.",
                    "success"
                )
                return
            }
            throw err
        }).catch ((err) => {
            useNotification(
                "Ops!",
                "Não foi possível remover o produto, tente novamente.",
                "danger"
            )
        })
    }

    const productsColumns = [
        { field: "channel", headerName: "Canal", flex: .25 },
        { field: "name", headerName: "Nome", flex: .5 },
        { field: "description", headerName: "Descrição", flex: 1 },
        { field: "franchise_type", headerName: "Tipo Franquia", flex: .25 },
        { field: "franchise", headerName: "Franquia", flex: .25 },
        { field: "suggested_price", headerName: "Preço Sugerido", flex: .25,
            renderCell: (params) => new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(params.value)
        },
        { field: "action", headerName: "Ações", flex: .25, filterable: false,
            renderCell: (params) => (
                <Box>
                    <Button className="action-button"
                        onClick={() => setProduct(JSON.parse(JSON.stringify(params.row)))}>
                        <EditIcon />
                    </Button>
                    <Button className="action-button delete"
                        onClick={() => setToDeleteProduct(params.row)}>
                        <DeleteIcon />
                    </Button>
                </Box>
            )
        }
    ]

    const CustomToolbar = () => (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <Box className='toolbarLeft'>
                <GridToolbarColumnsButton size="small" />
                <GridToolbarFilterButton size="small" className={'filterControl'} />
                <LoadingButton size="small" component="label" startIcon={<AddCircleIcon/>}
                    onClick={() => {
                        setProduct({
                            channel        : "",
                            name           : "",
                            description    : "",
                            franchise_type : "",
                            franchise      : "",
                        })
                    }}
                >
                    Adicionar
                </LoadingButton>
            </Box>
        </GridToolbarContainer>
    )

    return (
        <Box className="products-container">
            {toDeleteProduct &&
                <ConfirmationDialogCaptcha open={toDeleteProduct}
                    title="Remover produto"
                    description={
                        <span>
                            Para remover o produto {toDeleteProduct.name}, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.
                        </span>
                    }
                    handleClose={() => setToDeleteProduct(null)}
                    handleSubmit={() => deleteProduct(toDeleteProduct)}
                    captcha={'remover'}
                    alternateNo="Cancelar"
                    alternateYes="Confirmar"
                    alternateName=""
                />
            }
            {product &&
                <Dialog fullWidth maxWidth="sm" open={product !== null} onClose={() => setProduct(null)}>
                    <DialogTitle>{(product.id) ? "Editar produto" : "Adicionar produto"}</DialogTitle>
                    <Divider/>
                    <DialogContent style={{ paddingTop: "2rem" }}>
                        {product && (
                            <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <TextField
                                    label="Canal"
                                    value={product.channel}
                                    onChange={(event) => setProduct((prevState) => ({
                                        ...prevState, 
                                        channel: event.target.value
                                    }))}
                                    fullWidth
                                    size="small"
                                />
                                <TextField
                                    label="Nome"
                                    value={product.name}
                                    onChange={(event) => setProduct((prevState) => ({
                                        ...prevState, 
                                        name: event.target.value
                                    }))}
                                    fullWidth
                                    size="small"
                                />
                                <TextField
                                    label="Descrição"
                                    value={product.description}
                                    onChange={(event) => setProduct((prevState) => ({
                                        ...prevState, 
                                        description: event.target.value
                                    }))}
                                    fullWidth
                                    size="small"
                                />
                                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <InputLabel>Tipo Franquia</InputLabel>
                                        <Select
                                            value={product.franchise_type}
                                            onChange={(event) => setProduct((prevState) => ({
                                                ...prevState, 
                                                franchise_type: event.target.value
                                            }))}
                                            label="Tipo Franquia"
                                        >
                                            <MenuItem value="N/A">N/A</MenuItem>
                                            <MenuItem value="Gb">Gb</MenuItem>
                                            <MenuItem value="In">In</MenuItem>
                                            <MenuItem value="Out">Out</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Franquia"
                                        value={product.franchise}
                                        onChange={(event) => setProduct((prevState) => ({
                                            ...prevState, 
                                            franchise: event.target.value
                                        }))}
                                        fullWidth
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                    <TextField
                                        label="Preço Sugerido"
                                        value={product.suggested_price}
                                        onChange={(event) => setProduct((prevState) => ({
                                            ...prevState, 
                                            suggested_price: event.target.value
                                        }))}
                                        fullWidth
                                        size="small"
                                        inputProps={{ min: 0, step: "0.01" }}
                                        InputProps={{
                                            inputComponent: NumericFormatInput
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setProduct(null)}>Cancelar</Button>
                      <Button onClick={() => (product.id) ? updateProduct(product) : createProduct(product)}>Salvar</Button>
                    </DialogActions>
                  </Dialog>
            }
            <ThemeProvider theme={theme}>
                <DataGridPro
                    rows={products}
                    columns={productsColumns}
                    density={"compact"}
                    pagination
                    paginationMode="client"
                    pageSize={20}
                    rowsPerPageOptions={[20, 50, 100]}
                    disableSelectionOnClick
                    components={{ Toolbar: CustomToolbar }}
                />
            </ThemeProvider>
        </Box>
    )
}

export default Products
