import React, { useReducer, useEffect } from 'react'

import {
    DataGridPro,
    ptBR,
    gridClasses,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarContainer
} from '@mui/x-data-grid-pro';
import { Box, Button } from '@mui/material';
import moment from 'moment';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import defaultReducer from '../../../utils/defaultReducer';
import ConfirmationDialog from '../../Dialogs/Confirmation';
import AutomatedReportDialog from '../../Dialogs/AutomatedReportDialog';
import api from '../../../api';
import { getSession } from "../../../auth";
import useNotification from '../../Notification';


const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const automatedReportsCustomToolbar = () => {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

const getAutomatedReportsColumns = (editRow, deleteRow) => {
    return [
        {
            field: '_type', headerName: 'Relatório', flex: .5,
            renderCell: (params) => {
                let report = '';
                switch (params.value) {
                    case 'chat':
                        report = 'Contatos';
                        break;
                    case 'auditing':
                        report = 'Consumo Whatsapp';
                        break;
                    case 'notification':
                        report = 'Notificação';
                        break;
                    case 'agents':
                        report = 'Média por operador';
                        break;
                        
                    // case 'calls':
                    //     report = 'Chamadas de voz';
                    //     break;
                    default:
                        break;
                }
                return (<Box>{report}</Box>);
            }
        },
        {
            field: 'filters_changed', headerName: 'Filtros', flex: 1,
            renderCell: (params) => {
                let filters = 'Sem filtros.';
                if (Object.keys(params.value).length != 0) {
                    filters = '';
                    Object.keys(params.value).forEach((each) => {
                        filters = `${filters}, ${each} ${params.value[each]}`;
                    });
                    filters = filters.slice(2);
                }
                return (<Box>{filters}</Box>);
            }
        },
        {
            field: 'period', headerName: 'Período', flex: .5,
            renderCell: (params) => {
                let period = '';
                switch (params.value) {
                    case 'today':
                        period = 'Hoje';
                        break;
                    case 'last_day':
                        period = 'Último dia';
                        break;
                    case 'last_week':
                        period = 'Última semana';
                        break;
                    case 'last_month':
                        period = 'Último mês';
                        break;
                    case 'last_3_months':
                        period = 'Últimos 3 meses';
                        break;
                    case 'last_6_months':
                        period = 'Últimos 6 meses';
                        break;
                    default:
                        break;
                }
                return (<Box>{period}</Box>);
            }
        },
        { field: 'recipients', headerName: 'Destinatários', flex: 1 },
        {
            field: 'active', headerName: 'Status', flex: .25,
            renderCell: (params) => {
                if (params.value) {
                    return (
                        <Box sx={{ backgroundColor: "#5399db" }} className={'notification-status'}>
                            Agendado
                        </Box>
                    );
                } else {
                    return (
                        <Box sx={{ backgroundColor: "#ed6c02" }} className={'notification-status'}>
                            Inativo
                        </Box>
                    );
                }
            }
        },
        {
            field: 'created_at', type: 'dateTime', headerName: 'Data de criação', flex: .5, hide: true,
            valueGetter: ({ value }) => {
                if (value) {
                    const date = new Date(value);
                    date.setHours(date.getHours() - 3);
                    return date;
                }
            },
            renderCell: (params) => (
                <Box>
                    {params.value ? moment(params.value).format("DD/MM/YYYY HH:mm:ss").toString() : ''}
                </Box>
            ),
        },
        {
            field: 'last_run', type: 'dateTime', headerName: 'Última execução', flex: .5, hide: true,
            valueGetter: ({ value }) => {
                if (value) {
                    const date = new Date(value);
                    date.setHours(date.getHours() - 3);
                    return date;
                }
            },
            renderCell: (params) => (
                <Box>
                    {params.value ? moment(params.value).format("DD/MM/YYYY HH:mm:ss").toString() : ''}
                </Box>
            ),
        },
        {
            field: 'next_execution', type: 'dateTime', headerName: 'Próxima execução', flex: .5,
            valueGetter: ({ value }) => {
                if (value) {
                    const date = new Date(value);
                    date.setHours(date.getHours() - 3);
                    return date;
                }
            },
            renderCell: (params) => (
                <Box>
                    {params.value ? moment(params.value).format("DD/MM/YYYY HH:mm:ss").toString() : ''}
                </Box>
            ),
        },
        {
            field: 'actions', headerName: 'Ações', flex: 0.2, filterable: false, sortable: false,
            renderCell: (params) => (
                <Box>
                    <Button className="action-button"
                        onClick={() => {
                            editRow(params.row)
                        }}>
                        <EditIcon />
                    </Button>
                    <Button className="action-button delete"
                        onClick={() => {
                            deleteRow(params.row)
                        }}>
                        <TrashIcon />
                    </Button>
                </Box>
            )
        },
    ];
}

const ScheduleView = () => {

    const [state, setState] = useReducer(defaultReducer, {
        pageSize: 25,
        automatedReports: [],
        showDeleteDialog: null,
        showEditDialog: null,
        enterpriseId: getSession().profile.enterprise.id,
        loading: false,
        graphics: false,
        schedule: true,
        authenticate: false,
        automatedReportsColumns: []
    })


    // what to do when some data is edited.
    const submitEditDialog = async (data) => {
        const id = state.automatedReports[0].id; 
        api.patch(`/api/enterprises/${state.enterpriseId}/automated_report/${id}/`, data)
            .then((res) => {
                if (res.status == 200) {
                    useNotification(
                        'Editado!',
                        'Agendamento alterado com sucesso!',
                        'success');
                    setState({ showEditDialog: null });
                    initialize();
                    return;
                }
                throw res;
            })
            .catch(async (err) => {
                console.error(err);
                await useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
            });
        
        return
    }

    // what to do when some data is deleted.
    const submitDeleteDialog = async (data) => {
        const id = state.showDeleteDialog.id
        api.delete(`/api/enterprises/${state.enterpriseId}/automated_report/${id}/`)
            .then((res) => {
                if (res.status == 204) {
                    useNotification(
                        'Removido!',
                        'Agendamento removido com sucesso!',
                        'success');
                    setState({ showDeleteDialog: null });
                    initialize();
                    return;
                }
                throw res;
            })
            .catch(async (err) => {
                console.error(err);
                await useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
            });
        
        return
    }

    // initialize data and feed automatedReports
    const initialize = async () => {
        await getData();
    }


    const getData = async () => {
        const url = `/api/enterprises/${state.enterpriseId}/automated_report/`;
        api.get(url, {timeout: 10000}).then(response => {
            if (response.status === 200) {
                setState({
                    automatedReports: response.data,
                    loading: false
                });
                return
            }
            throw err;
        }).catch(err => {
            console.error('Error fetching automated reports: ',err);
        });
    }

    useEffect(()=>{
        initialize()
    
    }, [])

    return (
        <>
            {state.showEditDialog &&
                <AutomatedReportDialog open={true}
                    handleClose={() => setState({ showEditDialog: null })}
                    handleSubmit={(e) => {
                        submitEditDialog(e)
                    }}
                    selectedRow={state.showEditDialog}
                />}
            {state.showDeleteDialog &&
                <ConfirmationDialog open={true}
                    title="Excluir agendamento!"
                    description="Tem certeza que deseja excluir esse agendamento de relatório?"
                    handleClose={() => setState({ showDeleteDialog: null })}
                    handleSubmit={(e) => {
                        submitDeleteDialog(e)
                        }}
                />
            }
            <StyledEngineProvider>
                <ThemeProvider theme={theme}>
                    <DataGridPro
                        pageSize={state.pageSize}
                        pagination
                        disableSelectionOnClick
                        disableDensitySelector
                        density="compact"
                        columns={getAutomatedReportsColumns(
                            (data) => setState({ ...state, showEditDialog: data }),
                            (data) => setState({ ...state, showDeleteDialog: data })
                        )}
                        rows={state.automatedReports}
                        components={{ Toolbar: automatedReportsCustomToolbar }}
                    />
                </ThemeProvider>
            </StyledEngineProvider >
        </>
    )
}

export default ScheduleView