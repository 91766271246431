import React, { useState, useEffect, useContext, useMemo } from "react";

import './styles.css';
import "./ConversationList/styles.css";
import { Box, Grid, List } from "@mui/material";

import FilterComponent from "./FilterComponent";
import SortComponent from "./SortComponent";
import ToogleJustMe from "./JustMe";
import QueueContainer from "./QueueContainer";
import api from "../../api";

import { getSession } from "../../auth";
import MessageComponent from "./MessageComponent";
import useNotification from "../Notification";
import { QueueSocket } from "../../routes";
import FinishedQueueContainer from "./FinishedQueueContainer";


let running = false

const subsObj = (objOld, objNew) => {
	const parsed = {}
	objOld.map(each => { parsed[each.id] = each })
	const newobj = {}
	if (Array.isArray(objNew)) {
		objNew.map(each => { newobj[each.id] = each })
		return Object.values({ ...parsed, ...newobj })
	}
	newobj[objNew.id] = objNew
	return Object.values({ ...parsed, ...newobj })
}

const ManageCalls = () => {

	const { chatState } = useContext(QueueSocket)

	const limit = 6
	const user = getSession();
	const settings = user.profile.enterprise.settings
	const enterpriseId = user.profile.enterprise.id
	const finished_infinite_scroll = user.profile.enterprise.finished_infinite_scroll
	const agentId = user.profile.agent_id
	let agentCanUpdateContact = true
	const [notificationAudio, setNotificationAudio] = useState('')
	const [renderKeyList, setRenderKeyList] = useState(0)
	const [accountcode, setAccountcode] = useState(0)
	const [loading, setLoading] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const [selectedChat, setSelectedChat] = useState()
	const [predefinedMessages, setPredefinedMessages] = useState([])
	const [templates, setTemplates] = useState([])
	const [enterpriseQueues, setEnterpriseQueues] = useState([])
	const [refresh, setRefresh] = useState(false)
	const [filterSettings, setFilterSettings] = useState()

	const [botAdded, setBotAdded] = useState(false)
	const [queueAdded, setQueueAdded] = useState(false)
	const [onchatAdded, setOnchatAdded] = useState(false)
	const [reactivatedAdded, setReactivatedAdded] = useState(false)
	const [finishedAdded, setFinishedAdded] = useState(false)
	const [heartbeat, setHeartbeat] = useState(false)
	const [initHeartbeat, setInitHeartbeat] = useState(false)
	const [allGroups, setAllGroups] = useState([])
	const [onChatAddedMessages, setOnChatAddedMessages] = useState(0)
	const [onQueueAddedMessages, setOnQueueAddedMessages] = useState(0)
	const [orderBy, setOrderBy] = useState({
		'bot': '-id',
		'queue': '-id',
		'onchat': '-id',
		'reactivated': '-id',
		'finished': '-id',
	})

    const getGroups = async () => {
        const response = await api.get(`api/enterprises/${enterpriseId}/contact_group/`)
        if (response.status === 200)
            setAllGroups(response.data)
    }
	
	const reloadState = () => {
		setRefresh(p => !p)
	}

	const refreshOrder = (where) => {
		return (newOrder) => {
			orderBy[where] = newOrder

			allChats[`${where}State`] = []
			allChats[`${where}Amount`] = 0

			setOrderBy(orderBy)
			getData(where)

			switch (where) {
				case "queue":
					setQueueAdded(p => !p)
					break
				case "onchat":
					setOnchatAdded(p => !p)
					break;
			}
			reloadState()
		}
	}

	const [allChats, setAllChats] = useState(
		{
			queueState: [],
			queueAmount: 0,
			onchatState: [],
			onchatAmount: 0,
			reactivatedState: [],
			reactivatedAmount: 0,
			finishedState: [],
			finishedAmount: 0,
			botState: [],
			botAmount: 0
		}
	)


	useEffect(async () => {
		if (!filterSettings || filterSettings.data == '')
			return
		setLoading(true)

		Object.assign(allChats, {
			queueState: [],
			queueAmount: 0,
			onchatState: [],
			onchatAmount: 0,
			reactivatedState: [],
			reactivatedAmount: 0,
			finishedState: [],
			finishedAmount: 0,
			botState: [],
			botAmount: 0
		})

		const promises = []
		promises.push(getData('bot'))
		promises.push(getData('queue'))
		promises.push(getData('onchat'))
		promises.push(getData('reactivated'))
		if (!finished_infinite_scroll)
			promises.push(getData('finished'))


		await Promise.all(promises)

		setBotAdded(p => !p)
		setQueueAdded(p => !p)
		setOnchatAdded(p => !p)
		setReactivatedAdded(p => !p)
		if (!finished_infinite_scroll)
			setFinishedAdded(p => !p)


		setLoading(false)
	}, [filterSettings, orderBy])

	const countData = async () => {

		let url = 'api/enterprises/' + enterpriseId + `/calls/chat/count/?manage=True`

		if (filterSettings?.data)
			url = url.concat(filterSettings.data)

		const res = await api.get(url)

		if (res.status == 200)
			return res.data
		else {
			if (filterSettings){
                setFilterSettings(null)
                useNotification(
                    'Informação',
                    `Seus filtros foram desabilitados para melhorar o tempo de resposta.`,
                    'info'
                )
            }
		}

		return false
	}

	useEffect(() => {

		if (settings.websockets)
			return
		
		getGroups()
		
		setTimeout(() => {
			setInterval(() => setHeartbeat(p => !p), 5000)
		}, 1000)
		setInitHeartbeat(true)
	}, [])

	useEffect(async () => {

		if (running === true)
			return

		if (initHeartbeat) {
			running = true

			const data = await countData()

			const notificate = { queues: 0, messages: 0, chats: 0 }

			if (!data) {
				running = false
				return;
			}

			const new_bot = data.bot
			const new_queued = data.queued
			const new_queued_messages = data.unreadqueuedmessages
			const new_onchat = data.onchat
			const new_onchat_messages = data.unreadonchatmessages
			const new_reactivated = data.reactivated
			const new_finished = data.finished

			if (new_bot != allChats['botAmount']) {
				allChats['botState'] = []
				setBotAdded(p => !p)
			}

			let renownqueue = 0

			if (new_queued != allChats['queueAmount']) {
				notificate.queues = new_queued - allChats['queueAmount']
				renownqueue = 1
			}

			if (new_queued_messages != onQueueAddedMessages) {
				renownqueue = 1
				if (new_queued_messages > onQueueAddedMessages)
					notificate.messages = new_queued_messages - onQueueAddedMessages
			}


			if (renownqueue === 1) {
				allChats['queueState'] = []
				setOnQueueAddedMessages(new_queued_messages)
				setQueueAdded(p => !p)
			}

			let renewonchat = 0

			if (onChatAddedMessages != new_onchat_messages) {

				if (new_onchat_messages > onChatAddedMessages)
					notificate.messages += new_onchat_messages - onChatAddedMessages

				renewonchat = 1

				setOnChatAddedMessages(notificate.messages)
				if (selectedChat && selectedChat.lh_chat_id) {
					//dont need to wait
					api.get(`api/enterprises/${enterpriseId}/calls/chat/set_read/${selectedChat.lh_chat_id}/`, { timeout: 0 })
				}
			}


			if (new_onchat != allChats['onchatAmount']) {
				renewonchat = 1
			}

			if (renewonchat) {
				allChats['onchatState'] = []
				setOnchatAdded(p => !p)
			}

			if (new_reactivated != allChats['reactivatedAmount']) {

				allChats['reactivatedState'] = []

				setReactivatedAdded(p => !p)
			}

			if (!finished_infinite_scroll && new_finished != allChats['finishedAmount']) {

				allChats['finishedState'] = []
				setFinishedAdded(p => !p)
			}

			if (finished_infinite_scroll){
				allChats['finishedAmount'] = new_finished
			}

		}

		running = false
	}, [heartbeat])

	const getData = async (where, offset = 0, channel = 0) => {
		const ob = orderBy[where]
		let url = 'api/enterprises/' + enterpriseId + `/calls/chat/${where}/?limit=${limit}&offset=${offset}&orderBy=${ob}`

		if (channel != 0)
			url = url.concat(`&channel=${channel}`)

		if (filterSettings?.data) {
			url = url.concat(filterSettings.data)
		}


		const res = await api.get(url, { timeout: 0 })
		if (res.status == 200) {

			let results = res.data.results

			//case we are receiving something on websocket
			if (channel != 0)
				return results

			//Add indexes from offset to chats
			results.map((chat, index) => {
				chat.position = offset + index;
				results[index] = { ...chat, ...chat.lhc }
			})

			let newChats = subsObj(allChats[`${where}State`], results)

			const newState = {}
			newState[`${where}State`] = newChats
			newState[`${where}Amount`] = res.data.count

			setAllChats(prevState => ({ ...prevState, ...newState }))

			return true
		} else {
			if (filterSettings){
                setFilterSettings(null)
                useNotification(
                    'Informação',
                    `Seus filtros foram desabilitados para melhorar o tempo de resposta.`,
                    'info'
                )
            }
		}

		return false
	}

	const getTemplates = async () => {
		await api.get(`/api/enterprises/${enterpriseId}/templates/`)
			.then((res) => {
				if (res.status == 200) {
					let newtemplates = res.data.waba_templates.filter((template) => {
						return (template.status == 'approved' &&
							template.language == 'pt_BR' &&
							!template.name.includes('sample_'))
					});
					setTemplates(newtemplates)
					return;
				}
				throw res;
			})
			.catch((err) => {
				console.error(err);
			});
	}

	const getPredefinedMessages = async () => {
		const ent_mes = api.get(`/api/enterprises/${enterpriseId}/predefinedmessages/`)
		const usr_mes = api.get(`/api/users/${user.id}/predefinedmessages/`)

		const [ent_res, usr_res] = await Promise.all([ent_mes, usr_mes])
		let predefinedData = {}

		if (usr_res.status == 200) {
			predefinedData = { ...predefinedData, ...usr_res.data }
		}
		else {
			console.error(usr_res.err);
		}

		if (ent_res.status == 200) {
			//const modifiedData = ent_res.data.map(each => each.category).filter((value, index, array) => array.indexOf(value) === index)
			ent_res.data.map(each => {
				const cat = `${each.category} - Geral`
				if (cat in predefinedData)
					predefinedData[cat].push({ id: each.id, message: each.message })
				else
					predefinedData[cat] = [{ id: each.id, message: each.message }]
			})
		}
		else {
			console.error(usr_res.err);
		}


		setPredefinedMessages(predefinedData)
	}

	//setPredefinedMessages

	const getEnterprise = async () => {
		await api.get(`/api/enterprises/${enterpriseId}/`)
			.then((res) => {
				if (res.status == 200) {
					if (res.data.settings) {
						const settings = res.data.settings;
						agentCanUpdateContact = settings.agent_can_update_contact;


						setNotificationAudio(res.data.audio_notification)
						setRenderKeyList(Math.random())

					}

					if ("Notification" in window) {
						// I have permission to receive notification
						if (Notification.permission === "granted") {
							useNotification(
								'Informação útil!',
								'Mantenha a tela de Chat aberta para poder receber as notificações de novas mensagens, quando estiver em outra aba do navegador.',
								'info'
							);
						} else {
							useNotification(
								'Atenção!',
								'Mantenha as notificações ativas no seu navegador para sempre ser informado de uma nova mensagem no seu Kwik.',
								'warning'
							);
						}
					}
					setAccountcode(res.data.accountcode)
					setEnterpriseQueues(res.data.queues)
					return;
				}
				throw res;
			})
			.catch((error) => {
				console.error(error);
			});
	}

	useEffect(async () => {

		setLoading(true)
		const promises = []
		promises.push(getTemplates())
		promises.push(getPredefinedMessages())
		promises.push(getEnterprise())

		await Promise.all(promises)

		setLoading(false)

	}, [])

	const itemClicked = async (e) => {
		setSelectedChat(e)
		setShowModal(true)
	}

	const addChat = (newchat, where) => {
		if (!newchat || newchat.length == 0)
			return
		const chat = newchat[0]

		//Let it remove, but don't let it add if we are filtering by queue.
		if (chatState?.queue && filterSettings?.queues?.length > 0 && filterSettings.queues.includes(chatState.queue))
			return


		allChats[`${where}State`].map(each => { each.position += 1 })
		allChats[`${where}Amount`] += 1
		chat.position = 0
		allChats[`${where}State`] = subsObj(allChats[`${where}State`], chat)

		switch (where) {
			case 'bot':
				setBotAdded(p => !p)
				break
			case 'queue':
				setQueueAdded(p => !p)
				break
			case 'onchat':
				setOnchatAdded(p => !p)
				break
			case 'reactivated':
				setReactivatedAdded(p => !p)
				break
			case 'finished':
				setFinishedAdded(p => !p)
				break
			default:
				break
		}

	}

	const removeChat = (parameter, where) => {
		let flag = 'channel'

		if (where == 'reactivated')
			flag = 'callerid'

		const foundChat = allChats[`${where}State`].find(oldChat => oldChat[flag] == parameter)

		if (!foundChat)
			return
		allChats[`${where}State`].map(each => {
			if (foundChat.position < each.position)
				each.position -= 1
		})
		allChats[`${where}State`] = allChats[`${where}State`].filter(oldChat => oldChat[flag] != parameter)
		allChats[`${where}Amount`] -= 1
		allChats[`${where}Added`] -= 1

		return foundChat
	}

	//If new chat, we should add it to the respective list accordingly
	const webSocketGetChats = async () => {
		if (chatState && chatState.new) {
			chatState.new = false
			const message = chatState.message
			const channel = chatState.channel



			let newchat = {}
			let removedChat = {}

			switch (message) {
				//Add chat on queue and offset actualchats

				case 'NEW_CHAT':
					newchat = await getData('queue', 0, channel)
					addChat(newchat, 'queue')
					break;
				case 'DROP_CHAT':
					removedChat = removeChat(channel.channel, 'onchat')
					if (removedChat?.uniqueid == selectedChat?.uniqueid) {
						setShowModal(false)
						setSelectedChat({})
					}
					reloadState()
					return
				//Remove chat from queue/reactivated and add onchat, deoffset queue, offset onchat
				case 'ONCHAT':
					newchat = await getData('onchat', 0, channel)
					removedChat = removeChat(channel, 'queue')
					removeChat(newchat[0]?.callerid, 'reactivated')
					addChat(newchat, 'onchat')
					if (selectedChat && newchat.length > 0 && selectedChat?.channel == newchat[0]?.channel)
						selectedChat.agent.name = newchat[0].agent.name

					break;
				//Here we are receiving a new message on queue or ONCHAT from the client or agent
				//We should get this message on channel and update our chat accordingly, because it already exists
				//But we will only do it for ONCHAT messages, otherwise we will ignore it.
				//We will not do it if we are currently on this chat to avoid doublegetting
				case 'NEW_MESSAGE':
					const oldchat = allChats['onchatState'].find(chat => chat.channel == channel)
					if (oldchat && selectedChat && oldchat.uniqueid != selectedChat.uniqueid) {
						const newmsg = await getData('onchat', 0, channel)
						if (newmsg && newmsg.length > 0) {
							oldchat.messages = newmsg[0].messages
							reloadState()
						}
					}
					return;
				//Add chat on reactivated and offsetchat
				case 'REACTIVATE':
					newchat = await getData('reactivated', 0, channel)
					addChat(newchat, 'reactivated')
					break;
				//Add chat on finished and offset actualchats
				case 'HANGUP':
					newchat = await getData('finished', 0, channel)
					removedChat = removeChat(channel, 'onchat')
					if (removedChat)
						addChat(newchat, 'finished')
					break;

				default:
					break;


			}
			reloadState()
			//drop chat if we are not the one moving it.
			if (newchat[0]?.agents_id != user.profile.agent_id && removedChat?.uniqueid == selectedChat?.uniqueid) {
				setShowModal(false)
				setSelectedChat({})
				return
			}

		}
	}
	useEffect(() => {
		webSocketGetChats()
	}, [chatState])

	const openedChat = useMemo(() => {
		return (
			<>
				{showModal && selectedChat ?
					<MessageComponent
						agentId={agentId}
						chat={selectedChat}
						getData={getData}
						onHandleClose={() => { setShowModal(false); setSelectedChat({}) }}
						enterpriseId={enterpriseId}
						templates={templates}
						queues={enterpriseQueues}
						predefinedMessages={predefinedMessages}
						setPredefinedMessages={setPredefinedMessages}
						setHeartbeat={setHeartbeat} 
						allGroups={allGroups}
					/>
					:
					null}
			</>
		)
	}, [selectedChat])

	const handleToggleChange = (toggled) => {
		setToogleJustMe(toggled);
	}

	return (
		<Box className="manage-calls-container">
			<Box className="manage-calls-header">
				<FilterComponent
					filterSettings={filterSettings}
					setFilterSettings={setFilterSettings}
					reloadState={reloadState}
					setHeartbeat={setHeartbeat}
					allGroups={allGroups}
				/>
			</Box>
			<Box className="manage-calls-content">
				{settings.bot &&
					<QueueContainer
						queueName={'Bot'}
						queueWhere={'bot'}
						queueAmount={allChats.botAmount}
						queueRow={allChats.botState}
						onClickItem={itemClicked}
						fetching={loading}
						limit={limit}
						callData={getData}
						queueAdded={botAdded}
						isManageCalls={true}
						activeChat={selectedChat}
					/>}
				<QueueContainer
					queueName={'Fila'}
					queueWhere={'queue'}
					queueAmount={allChats.queueAmount}
					queueRow={allChats.queueState}
					onClickItem={itemClicked}
					fetching={loading}
					limit={limit}
					callData={getData}
					queueAdded={queueAdded}
					isManageCalls={true}
					activeChat={selectedChat}
					sortComponent={<SortComponent orderBy={orderBy['queue']} updateData={refreshOrder('queue')} color='white' old />}
				/>

				<QueueContainer
					queueName={'Atendimentos'}
					queueWhere={'onchat'}
					queueAmount={allChats.onchatAmount}
					queueRow={allChats.onchatState}
					onClickItem={itemClicked}
					fetching={loading}
					limit={limit}
					callData={getData}
					queueAdded={onchatAdded}
					isManageCalls={true}
					activeChat={selectedChat}
				/>
				<QueueContainer
					queueName={'Pendentes'}
					queueWhere={'reactivated'}
					queueAmount={allChats.reactivatedAmount}
					queueRow={allChats.reactivatedState}
					onClickItem={itemClicked}
					fetching={loading}
					limit={limit}
					callData={getData}
					queueAdded={reactivatedAdded}
					isManageCalls={true}
					activeChat={selectedChat}
					ToggleJustMe={handleToggleChange} 
					color="white"
				/>
				{!finished_infinite_scroll ? <QueueContainer
					queueName={'Finalizados'}
					queueWhere={'finished'}
					queueAmount={allChats.finishedAmount}
					queueRow={allChats.finishedState}
					onClickItem={itemClicked}
					fetching={loading}
					limit={limit}
					callData={getData}
					queueAdded={finishedAdded}
					isManageCalls={true}
					activeChat={selectedChat}
				/> :
					<FinishedQueueContainer
						onClickItem={itemClicked}
						isManageCalls={true}
						activeChat={selectedChat}
						queueAmount={allChats.finishedAmount}
						filterSettings={filterSettings}
						setFilterSettings={setFilterSettings}
					/>
				}
			</Box>
			{openedChat}
		</Box>
	)
}

export default ManageCalls