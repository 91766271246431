import React, {useCallback, useEffect, useReducer, useState} from 'react'

import {Box} from '@mui/material'

import {lockedReducer} from '../../../utils/defaultReducer'
import GraphIcon from '@mui/icons-material/Equalizer';
import TableIcon from '@mui/icons-material/TableChart';
import CampaignIcon from '@mui/icons-material/Campaign';
import ApprovalIcon from '@mui/icons-material/Approval';

import Loading from '../../Loading';

import DetailedRegisterTable from './detailed_register_table';
import DetailedRegisterLeftMenu from './detailed_register_leftmenu'
import DetailedRegisterRightMenu from './detailed_register_rightmenu'
import DetailedRegisterFilters from './detailed_register_filters'
import AuthenticateFile from './authenticate_file';
import GraphView from './graph_view'
import ScheduleView from './schedule_view'

import "../DetailedRegister/styles.css";

import {buildColumns, get_report_names, get_report_values, get_report_data, initialize_date_parameters} from './detailed_register_functions'
import GraphsFilter from './Graphs/filter';
import GraphViewMain from './Graphs/main';

export const SPECIAL_REPORT_NAME = 'Contatos'

const report_tabs = {
    'auth'       : {'name':'Autenticar'  , 'icon':(<ApprovalIcon />),
                     component: (embed) => <AuthenticateFile {...embed}/>,
                     menu: () => <Box className="report-authenticate-title">
                                    Verificação de autenticidade de documento
                                 </Box>},
    'scheduling' : {'name':'Agendamentos', 'icon':(<CampaignIcon />), component: (embed) => <ScheduleView {...embed}/>
                                                                    , menu: (embed) => <DetailedRegisterLeftMenu {...embed} disabled/>},
    'graphs'     : {'name':'Gráficos'    , 'icon':(<GraphIcon />)   , component: (embed) => <GraphViewMain {...embed}/>
                                                                    , menu: (embed) => <GraphsFilter {...embed}/>},
    'reports'    : {'name':'Tabela'      , 'icon':(<TableIcon />)   , component: (embed) => <DetailedRegisterTable {...embed}/>
                                                                    , menu: (embed) => <DetailedRegisterLeftMenu {...embed} />}
}


const DetailedRegisterMain = () => {

    const [state, setState] = useReducer(lockedReducer, {
        report_names         : [],
        report_values        : [],
        selected_report_name : [],
        report_data          : [],
        loading              : false,
        page                 : 0,
        limit                : 25,
        offset               : 0,
        parameters           : {},
        selectedTab          : Object.keys(report_tabs)[3],
        filters              : [],
        selectedData         : [],
        visibilityModel      : [],
        activeDialog         : null,
        templates            : [],
        columns              : [],
        visibilityModel      : {},
        extraData            : {},
        filterModel          : { items: [] },
        sortModel            : [],
        reportMode           : localStorage.getItem('reportMode') || 'standard' || 'alternate'
    })
    
    const initialize = useCallback(async (initialState) => {

        setState({ loading : true })
        const nextState = {}

        nextState.report_names = await get_report_names()

        // Parse report URL parameters if existing
        const report = (new URLSearchParams(window.location.search)).get('report')
        nextState.selected_report_name = nextState.report_names[(report) ? report : 0] ?? nextState.report_names[0]
        nextState.parameters = initialize_date_parameters(nextState.selected_report_name?.data_type)

        if (nextState.selected_report_name?.name === SPECIAL_REPORT_NAME){
            nextState.sortModel = []
        }

        // Parse start/end dates URL parameters if existing
        const startDate = (new URLSearchParams(window.location.search)).get('startDate')
        nextState.parameters.date__gte = (startDate) ? startDate.split('T')[0] : nextState.parameters.date__gte
        nextState.parameters.time__gte = (startDate) ? startDate.split('T')[1] : nextState.parameters.time__gte
        const endDate = (new URLSearchParams(window.location.search)).get('endDate')
        nextState.parameters.date__lte = (endDate) ? endDate.split('T')[0] : nextState.parameters.date__lte
        nextState.parameters.time__lte = (endDate) ? endDate.split('T')[1] : nextState.parameters.time__lte

        nextState.report_values = await get_report_values(nextState.selected_report_name)
        nextState.report_data = await get_report_data(nextState)

        window.setInitialReport = async (report_name, data) => {
            setState({selectedTab: 'reports', loading: true})
            const newstate = {...nextState, ...data, selectedTab: 'reports'}

            newstate.selected_report_name = newstate.report_names.find(report => report.name === report_name)
            newstate.report_values = await get_report_values(newstate.selected_report_name)

            newstate.report_data = await get_report_data(newstate)
            setState({
                loading : false,
                ...buildColumns(newstate.report_values, setState),
                ...newstate
            })
        }

        setState({
            loading : false,
            ...buildColumns(nextState.report_values, setState),
            ...nextState
        })

        return () => {
            window.setInitialReport = () => {}
        }
    }, [])

    useEffect(() => {
        initialize(state)




    }, [] )

    const embedData = {
        parent:state,
        setParent: setState
    }

    return (
        <Box style={{ height: "100%", padding: "1rem" }}>
            <Loading loading={state.loading} />
            {state.activeDialog}
            <Box className="report-container">
                <Box className="report-header">
                {report_tabs[state.selectedTab].menu(embedData)}
                    <DetailedRegisterRightMenu {...embedData} options={report_tabs}/> 
                </Box>
                <Box className="report-filters">
                    <DetailedRegisterFilters {...embedData} />
                </Box>
                <Box className="report-content">
                    {report_tabs[state.selectedTab].component(embedData)}
                </Box>
            </Box>
        </Box>
    )
}

export default DetailedRegisterMain