import React from "react";

import { Box, Button, Dialog } from "@mui/material";

import Loading from '../../Loading';
import useValidationError from '../../ErrorValidation';
import useNotification from "../../Notification";
import RestrictedWordsDialog from '../../Dialogs/RestrictedWords';

import {
	DataGridPro,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	gridClasses,
	LicenseInfo,
	ptBR
} from '@mui/x-data-grid-pro';

import { ptBR as corePtBR } from '@mui/material/locale';

import {
	createTheme,
	ThemeProvider,
	StyledEngineProvider
} from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../../api';

import { getSession } from '../../../auth';

import { alphabeticalSort } from "../../../utils/alphabeticalSort";
import { stringOperators } from "../../../utils/filterOperators";

import "./styles.css";

const theme = createTheme({
	palette: {
		primary: { main: '#21446C' },
	},
}, ptBR, corePtBR);

class Settings extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			renderKeyDialog: 1,
			showCreateRestrictedWords: false,
			restrictedWords: [],
			showChangeRestrictedWords: false,
			selectedRestrictedWords: null
		};

		this.scout = this.props.scout && this.props.scout === 1 ? 1 : 0;
		this.queuesName = {}
	}

	getRestrictedWords = () => {
		this.setState({ loading: true });
		const params = new URLSearchParams({ scout: this.scout });

		api.get(`/api/enterprises/${this.props.enterpriseId}/restricted_words/?${params.toString()}`)
			.then((res) => {
				this.setState({ loading: false });

				if (res.status == 200 && this._isMounted) {
					let restrictedWords = [];

					res.data.forEach(async (data) => {
						await restrictedWords.push({
							id: data.id,
							name: data.name,
							words: (data.words.map((word) => word.word)).join(', '),
							queues:
								(data.queues.filter(queue => queue.queue).map(
									(queue) => (queue.queue && queue.queue.description) ? queue.queue.description : '')
								).join(', '),
							actions: data
						});
					});
					this.setState({ restrictedWords: restrictedWords });
				}
			}).catch((err) => {
				console.error(err);
			});
	}

	createRestrictedWords = (id, name, words, queues) => {
		api.post('api/LHC/restricted_words/', {
			name: name,
			accountcode: getSession().profile.enterprise.accountcode,
			words: words,
			queues: queues,
			scout: this.scout
		}).then(async (res) => {
			if (res.status == 201) {
				await this.setState({ showCreateRestrictedWords: false });

				await useNotification(
					'Sucesso!',
					'Lista de palavras restritas inserida com sucesso.',
					'success'
				);

				this.getRestrictedWords();

				return;
			}
			if (res.status >= 400 && res.status < 500) {
				await useValidationError(res.status, null);

				return;
			}
			throw res;
		}).catch(async (err) => {
			console.error(err);

			await useValidationError(err.status, null);
		})
	}

	changeRestrictedWordsRow = async (row) => {
		await this.setState({
			selectedRestrictedWords: row,
			showChangeRestrictedWords: true,
			showCreateRestrictedWords: false,
			renderKeyDialog: Math.random()
		});

	}

	changeRestrictedWords = (id, name, words, queues) => {
		api.patch(`api/LHC/restricted_words/${id}/`, {
			name: name,
			words: words,
			queues: queues
		}).then(async (res) => {
			if (res.status == 200) {
				await this.setState({ showChangeRestrictedWords: false });

				await useNotification(
					'Sucesso!',
					'Lista de palavras restritas atualizada com sucesso.',
					'success'
				);

				this.getRestrictedWords();

				return;
			}
			if (res.status >= 400 && res.status < 500) {
				await useValidationError(res.status, null);

				return;
			}

			throw res;
		}).catch(async (err) => {
			console.error(err);

			await useValidationError(err.status, null);
		})
	}

	deleteRestrictedWordsRow = (row) => {
		api.delete(`api/LHC/restricted_words/${row.id}/`).then(async (res) => {
			if (res.status == 204) {
				this.getRestrictedWords();

				return;
			}
			throw res;
		}).catch(async (err) => {
			console.error(err);

			await useValidationError(err.status, null);
		});
	}

	generateDialog = () => {
		if (this.state.showCreateRestrictedWords) {
			return (
				<RestrictedWordsDialog
					title="Palavras Restritas"
					buttonText="Criar"
					queuesName={this.queuesName}
					submit={this.createRestrictedWords}
					handleClose={() => this.setState({showCreateRestrictedWords:false, showChangeRestrictedWords:false})}
					scout={this.scout}
				/>
			);
		} else {
			return (
				<RestrictedWordsDialog
					title="Palavras Restritas"
					model={this.state.selectedRestrictedWords}
					key={this.state.renderKeyDialog}
					buttonText="Salvar"
					queuesName={this.queuesName}
					submit={this.changeRestrictedWords}
					handleClose={() => this.setState({showCreateRestrictedWords:false, showChangeRestrictedWords:false})}
					scout={this.scout}
				/>
			);
		}
	}

	componentWillMount() {
		this._isMounted = true;

		const user = getSession();
		let queuesName = [];

		api.get(`api/enterprises/${user.profile.enterprise.id}/queues/chat/`).then(res =>{
			if (res.status === 200){
				this.queuesName = res.data.sort((x,y) => alphabeticalSort(x,y,'description')).filter(each=>!each.disabled)
				this.getRestrictedWords();
			}
			}
			)
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	CustomToolbar = () => {

		return (
			<GridToolbarContainer className={gridClasses.toolbarContainer}>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<LoadingButton
					size="small"
					component="label"
					startIcon={<AddCircleIcon />}
					onClick={async () => {
						await this.setState({
							showCreateRestrictedWords: true,
							showChangeRestrictedWords: false,
							renderKey: Math.random()
						});
					}}
				>
					Adicionar
				</LoadingButton>
			</GridToolbarContainer>
		);
	}

	stringMask = (word) => {
		const firstWords = word[0]
		const lastWords = word.substring(1).replace(/./g, "*")
		return (firstWords+lastWords)
	}

	createColumns() {
		const columns = [
			{ field: 'name', headerName: 'Nome', flex: 1, filterOperators: stringOperators },
			{ field: 'words', headerName: 'Palavras Restritas', flex: 3, filterOperators: stringOperators,
			renderCell: (params)  => {
				const words = params?.value?.split(',').map(each => this.stringMask(each?.trim()))
				return (
				 <Box sx={{overflow:'hidden', textOverflow:'ellipsis'}}>{words.join(', ')}</Box>
			)}
		},
		];
		if (this.scout == 0) {
			columns.push(
				{ field: 'queues', headerName: 'Filas Associadas', flex: 2, filterOperators: stringOperators },
			);
		};

		columns.push(
			{
				field: 'actions', headerName: 'Ações', filterable: false, sortable: false,
				renderCell: (params) => (
					<Box>
						<Button className="action-button"
							onClick={() => this.changeRestrictedWordsRow(params.value)}>
							<EditIcon />
						</Button>
						<Button className="action-button delete"
							onClick={() => this.deleteRestrictedWordsRow(params.value)}>
							<DeleteIcon />
						</Button>
					</Box>
				)
			});
		return columns;
	}

	render() {
		return (
			<>
				<Loading loading={this.state.loading} />
				{(this.state.showCreateRestrictedWords || this.state.showChangeRestrictedWords) &&
					<Dialog open={true} fullWidth
					disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
					>
						{this.generateDialog()}
					</Dialog>
				}
				<Box className='BuilderContainer'>
					<Box className='BuilderContent' key={this.state.renderKey}>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<DataGridPro
									pageSize={100}
									pagination
									disableSelectionOnClick
									density="compact"
									columns={this.createColumns()}
									rows={this.state.restrictedWords}
									components={{
										Toolbar: this.CustomToolbar,
									}}
								/>
							</ThemeProvider>
						</StyledEngineProvider>
					</Box>
				</Box>
			</>
		);
	}
}

export default Settings;