import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  DialogActions,
  DialogContent,
  Box,
  TextField,
  DialogTitle,
  Dialog,
  Button,
  Slide,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Divider,
  Tooltip
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import api from '../../../../api';
import { getSession } from '../../../../auth';
import Loading from '../../../Loading';
import TextOnlyBody from './TextOnlyBody';
import MediaInteractiveBody from './MediaInteractiveBody';
import VisualizeTemplate from './VisualizeTemplate';
import TemplatePreview from './TemplatePreview';
import useNotification from '../../../Notification';
import './styles.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name                : '',
      category            : '',
      body                : '',
      bodyExample         : [],
      enterpriseId        : 0,
      loading             : false,
      sendLoading         : false,
      numberCharacters    : 0,
      bodyTypeSelectValue : 'STANDARD',
      mediaReady          : false,
      mediaBody           : [],
      preview             : false,
      number              : getSession().profile.enterprise.channels.map((each) => each.phone_number)?.[0] ?? null,
      metaNumbers         : []
    }
  }

  defineTypeOfBody = () => {
    if (this.props.templates && this.props.templates.componentes.length > 1)
      return 'MEDIA_INTERACTIVE'
    return 'STANDARD'
  }

  isMethodRead = () => {
    return this.props.readTemplate;
  }

  isMethodCopy = () => {
    return this.props.copyTemplate;
  }

  characterLimit = () => {
    if (this.state.name == '')
      return true
    if (this.state.category == '' || !this.state.category)
      return true
    if (this.state.numberCharacters >= 1 && this.state.numberCharacters <= 1024)
      return false;
    return true;
  }

  onChangeName = async (event) => {
    await this.setState({ name: event.target.value.toLocaleLowerCase().replaceAll(' ', '') });
    this.verifyAll()
  }

  onChangeCategory = async (event) => {
    await this.setState({ category: event.target.value });
    this.verifyAll()
  }

  onChangeNumber = async (event) => {
    await this.setState({ number: event.target.value });
    this.verifyAll()
  }

  onChangeBody = (event) => {
    const body = event.target.value;
    const numberCharacters = event.target.value.length;
    this.setState({ body: body, numberCharacters: numberCharacters });
  }

  addTemplates = () => {
    this.setState({ loading: true });
    this.setState({ sendLoading: true });

    const name = this.state.name ? this.state.name : this.props.template.name;
    const category = this.state.category ? this.state.category : this.props.template.category;
    const components = () => {
      if (this.state.bodyTypeSelectValue === 'STANDARD') {
        const newbody = [
          {
            type: "BODY",
            text: this.state.body
          }
        ]
        if (this.state.bodyExample.length > 0)
          newbody[0].example = { "body_text": [this.state.bodyExample] }
        return newbody
      }
      const headers = this.state.mediaBody.find(each => each.type === 'HEADER')
      if (headers && headers.example.header_text === '')
        delete headers.example
      return this.state.mediaBody
    }

    api.post(`/api/enterprises/${this.state.enterpriseId}/templates/`, {
      number     : this.state.number,
      name       : name,
      category   : category,
      components : components(),
      language   : "pt_BR"
    }, { timeout: 60000 }).then(async res => {
      await this.props.getTemplates();
      let status = res.status ? res.status : res.meta.http_code;
      let message = res.data?.meta?.developer_message
      const validation = await this.props.errorValidation({ status, message })
      this.setState({ loading: false });
      this.setState({ sendLoading: false });
      this.setState({ preview: false });
      if (!validation)
        return
      this.props.handleClose();
      if (res.status == 201 || res.status == 200)
        useNotification(
          'Sucesso!',
          'Template criado! A aprovação do template pode levar até 10 minutos, aguarde para poder utilizá-lo!',
          'success'
        );
    }).catch((err) => {
      this.props.handleClose();
      this.setState({ loading: false });
      this.setState({ sendLoading: false });
      useNotification(
        'error!',
        'Ocorreu um erro ao criar o template.',
        'danger'
      );
    });
  }

  verifyAll = async () => {
    let ready = false
    try {
      if (this.state.name == '')
        throw "Name not defined"
      if (this.state.category == '' || !this.state.category)
        throw "Category not defined"
      if (getSession().profile.enterprise.abstraction_layer && (this.state.number == '' || !this.state.number))
        throw "Number not defined"
      this.state.mediaBody.map(each => {
        if (each.type == 'HEADER' && (each.text == '' || this.matchRegLen(each.text) > 1))
          throw "Header not defined"
        if (each.type == 'BODY' && (each.text == '' || this.matchRegLen(each.text) > 10))
          throw "Body not defined"
        if (each.type == 'FOOTER' && (each.text == '' || this.matchRegLen(each.text) > 0))
          throw "Footer not defined"
        if (each.type == 'BUTTONS') {
          if (each.buttons.length == 0)
            throw "Buttons not defined"
          each.buttons.map(button => {
            if (button.type == 'URL' && (!('text' in button) || !('url' in button) || button.text == '' || button.url == ''))
              throw "URL button not defined"
            if (button.type == 'PHONE_NUMBER' && (!('text' in button) || !('phone_number' in button) || button.text == '' || button.phone_number == ''))
              throw "Phone number button not defined"
            if (button.type == 'QUICK_REPLY' && (!('text' in button) || button.text == ''))
              throw "Quick reply button not defined"
          })
        }
      })
      ready = true
    }
    catch (e) {
      // console.log(e)
    }
    if (ready && !this.state.mediaReady)
      this.setState({ mediaReady: true })
    else if (!ready && this.state.mediaReady)
      this.setState({ mediaReady: false })
  }

  getMediaValues = async (mediaBody) => {
    this.state.mediaBody = mediaBody
    this.verifyAll()
  }

  getMetaNumbers = () => {
    api.get(`api/enterprises/${getSession().profile.enterprise.id}/whatsapp/`)
      .then((res) => {
        this.setState({ metaNumbers: res.data })
      })
      .catch((err) => {})
  }

  componentWillMount() {
    const user = getSession();
    if (user) {
      this.state.enterpriseId = user.profile.enterprise.id;
    }
    let name = '', body = '', numberCharacters = 0;
    if (this.isMethodRead()) {
      name = this.props.template.name;
    } else if (this.isMethodCopy()) {
      name = `copia_de_${this.props.template.name}`;
    }
    if ((this.isMethodRead())
      && (this.props.template.components && this.props.template.components[0])) {
      body = this.props.template.components[0].text;
      if (body)
        numberCharacters = body.length;
    }

    const defaultCategory = Object.keys(this.props.categories).find(key => this.props.categories[key] === 'Utilidades');
    this.setState({
      name: name,
      body: body,
      numberCharacters: numberCharacters,
      category: defaultCategory || this.props.template.category
    });
    if (this.props.template.components) {
      if (this.props.template.components.length == 1 && this.props.template.components[0].type == 'BODY') {
        this.setState({ bodyTypeSelectValue: 'STANDARD' })
        this.setState({
          name: name,
          body: this.props.template.components[0].text,
          numberCharacters: this.props.template.components[0].text.length,
        });
      }
      else {
        this.setState({ bodyTypeSelectValue: 'MEDIA_INTERACTIVE' })
        this.setState({ mediaBody: this.props.template.components })
      }
    }

    this.getMetaNumbers()
  }

  matchRegLen = value => {
    if (!value)
      return 0
    const matcher = value.match(/{{\d+}}/g)
    if (matcher)
      return matcher.length
    return 0
  }

  render() {
    return (
      <Box>
        <Loading loading={this.state.loading} />
        {this.state.preview ?
          <TemplatePreview
            type={this.state.bodyTypeSelectValue}
            mediaValues={this.state.mediaBody}
            body={this.state.body}
            bodyExample={this.state.bodyExample}
            submit={() => this.addTemplates()}
            onHandleClose={() => this.setState({ preview: false })}
            parent={this}
          />
          : null
        }
        <Dialog
          open={this.props.open}
          keepMounted
          onClose={() => {}}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          disablePortal
          disableEnforceFocus
          sx={{ position: 'absolute' }}
        >
          <DialogTitle className={'group-flexbox'}>
            {this.isMethodRead() ? 'Visualização do template' : 'Novo template'}
            <Tooltip title="Fechar" >
              <Button className="action-button">
                <CloseIcon onClick={this.props.handleClose} />
              </Button>
            </Tooltip>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={3}>
              {getSession().profile.enterprise.abstraction_layer && this.isMethodRead() === false &&
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <FormControl
                    fullWidth
                    size="small"
                  >
                    <InputLabel id="number-select-label">Número</InputLabel>
                    <Select
                      labelId="number-select-label"
                      id="number-select"
                      value={this.state.number}
                      label="Categoria"
                      onChange={this.onChangeNumber}
                    >
                      {[...getSession().profile.enterprise.channels, ...this.state.metaNumbers].map((each) => each.phone_number).map((number, index) => (
                        <MenuItem key={index} value={number}>{number}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              }
              {this.isMethodRead() === false &&
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <TextField
                    className="input-name"
                    size="small"
                    label="Nome"
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={this.state.name}
                    disabled={this.isMethodRead()}
                    onChange={this.onChangeName}
                  />
                </Grid>
              }
              {this.isMethodRead() === false &&
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    disabled={this.isMethodRead()}
                  >
                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.category}
                      defaultValue={this.state.category}
                      label="Categoria"
                      onChange={this.onChangeCategory}
                    >
                      {Object.keys(this.props.categories).map((key, index) => (
                        <MenuItem key={index} value={key}>{this.props.categories[key]}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              }
              {'category' in this.props.template && !this.props.copyTemplate ?
                <>
                  <VisualizeTemplate
                    template={this.props.template}
                    category={this.props.categories[this.props.template.category]}
                    canVisualize={false}
                  />
                </> :
                (<>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl
                      fullWidth
                      size="small"
                      disabled={this.isMethodRead()}
                    >
                      <InputLabel id="demo-simple-select-label">Tipo de template</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.bodyTypeSelectValue}
                        label="Tipo de template"
                      >
                        <MenuItem
                          value="STANDARD"
                          onClick={() => this.setState({ bodyTypeSelectValue: 'STANDARD' })}
                        >
                          Padrão (apenas texto)
                        </MenuItem>
                        <MenuItem
                          value="MEDIA_INTERACTIVE"
                          onClick={() => this.setState({ bodyTypeSelectValue: 'MEDIA_INTERACTIVE' })}
                        >
                          Mídia e Interativo
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.state.bodyTypeSelectValue === 'STANDARD' ? (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextOnlyBody
                        isMethodRead={this.isMethodRead()}
                        defaultValue={this.state.body}
                        disabled={this.isMethodRead()}
                        onChangeFn={this.onChangeBody}
                        maxLength={1024}
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <MediaInteractiveBody
                        isMethodRead={this.isMethodRead()}
                        getMediaValues={this.getMediaValues}
                        initialize={this.props.copyTemplate}
                        mediaBody={this.state.mediaBody}
                      />
                    </Grid>
                  )}
                </>)
              }
            </Grid>
            {this.isMethodRead() == false &&
              <Box className="templates-footer">
                {this.state.bodyTypeSelectValue === 'STANDARD' && (
                  <>
                    <Box className="character-counter positionUnset" sx={{ marginRight: 1 }}>
                      {`${this.state.numberCharacters} / 1024 caracteres`}
                    </Box>
                    <Box className="character-counter positionUnset"  >
                      {`${this.matchRegLen(this.state.body)} / 10 variáveis`}
                    </Box>
                  </>
                )}
              </Box>
            }
          </DialogContent>
          <DialogActions style={{ padding: "10px 20px 15px 1.5rem" }}>
            {!this.state.preview &&
              <Box>
                <Button className="button-close" onClick={this.props.handleClose}>
                  Cancelar
                </Button>
                <LoadingButton className="button-created"
                  onClick={() => this.setState({ preview: true })}
                  disabled={this.isMethodRead() ? false : this.state.bodyTypeSelectValue === 'STANDARD' ? this.characterLimit() : !(this.state.mediaReady)}
                  loading={this.state.sendLoading}
                >
                  Ver template
                </LoadingButton>
              </Box>
            }
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Modal;