import React, { useEffect, useState, useCallback } from 'react'

import { Box, Alert, AlertTitle, IconButton, Tooltip } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import AddBotDialog from "./AddBotDialog/index.js"
import AddExceptionsDialog from "./AddExceptionsDialog/index.js"
import AddTemplateDialog from "./AddTemplateDialog/index.js"
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha/index.js';
import useNotification from "../../Notification/index.js";
import Loading from '../../Loading/index.js';
import api from '../../../api.js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import BlockIcon from '@mui/icons-material/Block';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentsDialog from './AttachmentsDialog/index.js';
import CircleIcon from '@mui/icons-material/Circle';

import { getSession } from '../../../auth.js';

import './styles.css'
import AddBotDialogOauth from './AddBotDialogOauth/index.js';

const fetchData = async (setLoading = () => { }, setBots = () => { }) => {
	const enterpriseId = getSession().profile.enterprise.id;
	setLoading(true);
	const res = await api.get(`/api/enterprises/${enterpriseId}/email/`);
	setLoading(false);

	if (res.status !== 200) {
		useNotification(
			'Ops!',
			'Não foi possível encontrar os emails.',
			'danger'
		);
		return
	}

	setBots(res.data)
};

const confirmateActivate = async (item, enabled, setLoading, bots, closeModal) => {
	const enterpriseId = getSession().profile.enterprise.id;
	setLoading(true)
	const res = await api.post(`/api/enterprises/${enterpriseId}/email/${item.id}/activate/`, { enabled: enabled })
	setLoading(false)
	if (res.status !== 200) {
		useNotification(
			'Ops!',
			res.data || 'Ocorreu um erro na requisição.',
			'danger'
		);
		return
	}
	const foundbot = bots.find(each => each.id === res.data.id)
	if (foundbot)
		Object.assign(foundbot, res.data)

	closeModal()
}

const Email = ({ enterprise }) => {

	const [loading, setLoading] = useState(false);
	const [bots, setBots] = useState([]);
	const [showAddBot, setShowAddBot] = useState(null)
	const [showAddBotOauth, setShowAddBotOauth] = useState(null)
	const [showAddExceptions, setShowAddExceptions] = useState(null)
	const [showAddTemplate, setShowAddTemplate] = useState(null)
	const [showAddAttachments, setShowAddAttachments] = useState(null)
	const [deleteModal, setDeleteModal] = useState(null)
	const [activateModal, setActivateModal] = useState(null)

	const deleteBot = async (id) => {
		const enterpriseId = getSession().profile.enterprise.id;
		setLoading(true)
		const res = await api.delete(`/api/enterprises/${enterpriseId}/email/${id}/`)
		setLoading(false)
		if (res.status !== 204) {
			useNotification(
				'Ops!',
				'Ocorreu um erro na requisição.',
				'danger'
			);
			return
		}
		setBots(bots.filter((bot) => bot.id != id))
		setDeleteModal(null)
	}

	const addBot = async (data) => {
		const enterpriseId = getSession().profile.enterprise.id;
		const id = data.id
		const validationData = { ...data }
		delete validationData.id

		let res = null
		setLoading(true)
		if (id === 0)
			res = await api.post(`/api/enterprises/${enterpriseId}/email/`, validationData)
		else
			res = await api.patch(`/api/enterprises/${enterpriseId}/email/${id}/`, validationData)
		setLoading(false)

		if (![200, 201].includes(res.status)) {
			if (res.status === 404) {
				useNotification(
					'Ops!',
					'Não foi possível registrar o email, verifique as suas credenciais.',
					'danger'
				)
			}
			else if (res.status === 403) {
				let message = 'Credenciais IMAP não configuradas corretamente'
				if (res.data === 'Failed to connect on smtp')
					message = 'Credenciais SMTP não configuradas corretamente'
				useNotification(
					'Ops!',
					message,
					'danger'
				)
			}

			else if (res.status === 409) {
				useNotification(
					'Ops!',
					'IMAP já vinculado a outra conta.',
					'danger'
				)
			}
			else {
				useNotification(
					'Ops!',
					'Ocorreu um erro na requisição.',
					'danger'
				)
			}
			return

		}

		const foundbot = bots.find(each => each.id === res.data.id)
		if (foundbot)
			Object.assign(foundbot, res.data)
		else
			setBots([...bots, res.data])

		setShowAddBot(null)
		setShowAddExceptions(null)
		setShowAddTemplate(null)
	}

	useEffect(() => {
		fetchData(setLoading, setBots)
	}, [])

	return (
		<Box className="input-settings settings-body">
			<Loading loading={loading} />
			{activateModal &&
				<ConfirmationDialogCaptcha
					title={<Box>{activateModal.enabled ? 'Desativar' : 'Ativar'} {activateModal.imap_username}</Box>}
					open={true}
					captcha={activateModal.enabled ? 'desativar' : 'ativar'}
					description={<Box>Confirma que deseja {activateModal.enabled ? 'desativar' : 'ativar'} a conta de e-mail {activateModal.imap_username}? Para isso, escreva <b>{activateModal.enabled ? 'desativar' : 'ativar'}</b> no campo abaixo.</Box>}
					handleClose={() => setActivateModal(null)}
					handleSubmit={() => confirmateActivate(activateModal, !activateModal.enabled, setLoading, bots, () => setActivateModal(null))}
				/>
			}
			{showAddBot &&
				<AddBotDialog
					data={showAddBot}
					handleClose={() => setShowAddBot(null)}
					submit={addBot}
				/>
			}
			{showAddBotOauth &&
				<AddBotDialogOauth
					data={showAddBotOauth}
					setBots={setBots}
					fetchData={fetchData}
					setLoading={setLoading}
					handleClose={() => setShowAddBotOauth(null)}
				/>
			}
			{showAddExceptions &&
				<AddExceptionsDialog
					data={showAddExceptions}
					handleClose={() => setShowAddExceptions(null)}
					submit={addBot}
				/>
			}
			{showAddTemplate &&
				<AddTemplateDialog
					data={showAddTemplate}
					handleClose={() => setShowAddTemplate(null)}
					submit={addBot}
				/>
			}
			{showAddAttachments &&
				<AttachmentsDialog
					data={showAddAttachments}
					handleClose={() => setShowAddAttachments(null)}
					submit={addBot}
				/>}
			{deleteModal ?
				<ConfirmationDialogCaptcha open={true}
					title="Excluir página."
					description={
						<span>
							Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.</span>}
					handleClose={() => { setDeleteModal(null) }}
					handleSubmit={() => { deleteBot(deleteModal) }}
					captcha={'remover'}
				/> : null
			}
			<Alert className="telegram-info" severity="info">
				<AlertTitle>
					Para saber como integrar o kwik com email <a href="https://instant.getoutline.com/s/kwik-integracoes/doc/integracao-com-e-mail-v5RCr1SG2H" target="_blank">clique aqui.</a>
				</AlertTitle>
				<Box className="push-s-bottom">
					É necessário:
				</Box>
				<Box>
					<p>Uma conta de email.</p>
					<p>Servidor SMTP e IMAP.</p>
				</Box>
				<Box className="push-s-top">
					Você pode adicionar seu email clicando no botão abaixo.
				</Box>
			</Alert>
			{bots.length !== 0 &&
				<>
					<Box className="category-title push-bottom">
						Email(s)
					</Box>
					<Box className="telegram-bots">
						<table>
							{bots.map((item, index) => (
								<tr>
									{item.imap_server && <td>IMAP: {item.imap_username} - SMTP: {item.smtp_username}</td>}
									{item.tenant_id && <td>Microsoft Exchange: {item.imap_username} - tenant_id: {item.tenant_id}</td>}
									<td>
										<Tooltip title="Status">
											<IconButton onClick={() => setActivateModal(item)}>
												<CircleIcon sx={{ color: item.enabled === true ? 'green !important' : 'red !important' }} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Visualizar">
											<IconButton onClick={() => { setShowAddBot(item) }}>
												<VisibilityIcon className='editicon' />
											</IconButton>
										</Tooltip>
										<Tooltip title="Endereços de e-mail ignorados">
											<IconButton onClick={() => { setShowAddExceptions(item) }}>
												<BlockIcon className='editicon' />
											</IconButton>
										</Tooltip>
										<Tooltip title="Editar template de e-mail">
											<IconButton onClick={() => { setShowAddTemplate(item) }}>
												<DescriptionIcon className='editicon' />
											</IconButton>
										</Tooltip>
										<Tooltip title="Recebimento e envio de anexos">
											<IconButton onClick={() => { setShowAddAttachments(item) }}>
												<AttachFileIcon className='editicon' />
											</IconButton>
										</Tooltip>
										<Tooltip title="Excluir">
											<IconButton onClick={() => setDeleteModal(item.id)}>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									</td>
								</tr>
							))}
						</table>
					</Box>
				</>
			}
			{bots.length < enterprise.platforms.email_boxes &&
				<Box className="email-add-button">
					<LoadingButton startIcon={<AddIcon />} variant="contained"
						onClick={() => setShowAddBot({})}
					>
						Adicionar IMAP e SMTP
					</LoadingButton>
					<LoadingButton startIcon={<AddIcon />} variant="contained"
						onClick={() => setShowAddBotOauth({})}
					>
						Adicionar Microsoft Exchange Oauth2
					</LoadingButton>
				</Box>
			}
			{bots.length >= enterprise.platforms.email_boxes &&
				<Box className="email-add-button">
					<LoadingButton startIcon={<AddIcon />} variant="contained"
						onClick={() => setShowAddBot({})}
						disabled={true}
					>
						Adicionar
					</LoadingButton>
					<Alert severity="info">
						Entre em contato com seu gerente de contas para contratar mais caixas de entrada. Caso já tenha adquirido a licença, fale com nosso suporte para liberação.
					</Alert>
				</Box>
			}
		</Box>
	)
}

export default Email
