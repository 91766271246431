import React from 'react';

import PropTypes from 'prop-types';

import { 
    Box,
    Grid,
    Select,
    LinearProgress,
    Typography
} from '@mui/material';

import TypographyKwik from '../../Typography';

import api from '../../../api';
import Loading from '../../Loading';
import Dialog360UsageChart from '../../Recharts/Dialog360UsageChart';
import useNotification from '../../Notification';

import './index.css';


function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: "flex", alignItems: "center"}}>
			<Box sx={{ width: "100%", mr: 1 }} className="usage-progress-bar">
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">
					{`${Math.round(props.value,)}%`}
				</Typography>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
};


class Usage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			loading  : true,
			data     : [],
			progress : 0,
		};

		this.months = [
			'Jan', 
			'Fev', 
			'Mar', 
			'Abr', 
			'Mai', 
			'Jun', 
			'Jul', 
			'Ago', 
			'Set', 
			'Out', 
			'Nov', 
			'Dez'
		];

		this.fullMonths = [
			'Janeiro', 
			'Fevereiro', 
			'Março', 
			'Abril', 
			'Maio', 
			'Junho', 
			'Julho', 
			'Agosto', 
			'Setembro', 
			'Outubro', 
			'Novembro', 
			'Dezembro'
		];
	}

	getBalance = (retries=6) => {
		api.get(`/api/enterprises/${this.props.enterpriseId}/360dialog/usage/`, { timeout: 27000 })
        .then((res) => {
			if (res.status !== 200) {
				useNotification(
					'Atenção',
					'Erro ao buscar dados de consumo. Por favor, tente novamente mais tarde.',
					'warning'
				)
				throw new Error('Failed [getBalance] after 6 retries');
			}
        	if (res.data === 'caching...') {
	        	if (retries > 0) {
	                return new Promise((resolve) => 
	                    setTimeout(() => resolve(this.getBalance(retries-1)), 5000)
	                );
	            } else {
					useNotification(
						'Atenção',
						'Erro ao buscar dados de consumo. Por favor, tente novamente mais tarde.',
						'warning'
					)
	                throw new Error('Failed [getBalance] after 6 retries');
	            }	
        	}
			
            let data = {};
            let packageProgress = 0
			if (res.data.length > 0) {
				res.data.forEach((clientRow, index) => {
					clientRow.usage.slice(-13).forEach((row, index) => {
						// Showing last 13 months
						const date = new Date(row.period_date.slice(0, -1));
						const month = this.months[date.getMonth()];
						const year = date.getFullYear().toString().slice(-2);

						let business = row.business_initiated_quantity;
						business += row.marketing_quantity;
						business += row.utility_quantity;
						business += row.authentication_quantity;
						
						let user = row.user_initiated_quantity;
						if (row.service_quantity != 0)
							user = row.service_quantity;

						let key = date.toISOString();

						if (! (key in data)) {
							data[key] = {
								name           : `${month} ${year}`,
								'Total'        : 0,
								'Ativas'       : 0,
								'Passivas'     : 0,
								'Marketing'    : 0,
								'Utilidade'    : 0,
								'Autenticação' : 0
							};
						}
		
						data[key]['Total']        += business + user;
						data[key]['Ativas']       += business;
						data[key]['Passivas']     += user;
						data[key]['Marketing']    += row.marketing_quantity;
						data[key]['Utilidade']    += row.utility_quantity;
						data[key]['Autenticação'] += row.authentication_quantity;

						packageProgress = (business + user) * 100 / 1000
					});
				})

				const months = Object.keys(data)
				months.sort()

				const sortedData = []
				for (const month of months)
					sortedData.push(data[month])

				this.setState({
					loading  : false,
					data     : sortedData,
					progress : packageProgress > 100 ? 100 : packageProgress
				});
				return 
			}

			this.setState({ loading: false })
        })
        .catch(err => {
        	this.setState({ loading: false })
        });
	}

	componentWillMount() {
        this.getBalance()
	}

	render() {
		const today = new Date();
		const month = this.fullMonths[today.getMonth()];
		const year  = today.getFullYear().toString();

		return (
			<>
	            <Loading loading={this.state.loading} message="Sincronizando consumo. Isto pode levar alguns segundos..."/>
	            {! this.state.loading &&
	                <Box className='whatsapp-consume'>
	                    <Box style={{ 
							fontSize   : "19px",
							fontWeight : "bold",
							color      : "#21446c",
							textAlign  : "center" 
	                    }}>
	                        Consumo {month} {year}
	                    </Box>
	                    <Box ml={13} mr={6} mt={5} style={{ textAlign: "right" }}>
	                        <LinearProgressWithLabel
	                            style={{
	                                height: "3vh",
	                                borderRadius: "3px"
	                            }}
	                            color={this.state.progress >= 75 ? "secondary" : "primary"}
	                            value={this.state.progress}
	                        />
	                    </Box>
	                    {this.state.progress >= 75 && this.state.progress < 100 &&
	                        <Box mt={1}>
	                            <TypographyKwik align="center"
	                                text="Você está prestes a atingir o limite do seu pacote. 
	                                	  Para cada atendimento realizado fora do limite, 
	                                	  serão cobrados custos extras."
	                                color="red"
	                                fontWeight="500"
	                            />
	                        </Box>
	                    }
	                    <Box mt={7} style={{ 
							fontSize   : "19px",
							fontWeight : "bold",
							color      : "#21446c",
							textAlign  : "center" 
	                    }}>
	                        Histórico de Consumo
	                    </Box>
	                    <Box className="usage-history-chart-container" 
	                    	mt={2} p={3} height={"50vh"}>
	                        <Dialog360UsageChart
	                            data={this.state.data}
	                        />
	                    </Box>
	                </Box>
	            }
	        </>
		);
	}
}

export default Usage;
