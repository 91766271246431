import react, { useEffect, useReducer } from 'react'
import api from '../../api'
import { lockedReducer } from '../../utils/defaultReducer'

import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Tooltip
} from "@mui/material"

import { createTheme, ThemeProvider } from "@mui/material/styles"

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    ptBR
} from "@mui/x-data-grid-pro"

import { ptBR as corePtBR } from "@mui/material/locale"

import EditIcon from "@mui/icons-material/Edit"
import CloseIcon from '@mui/icons-material/Close'
import Loading from '../Loading'

import IntegerOnly from '../../utils/IntegerOnly'

const initialize = async (setState) => {
    setState({ loading: true })
    const res = await api.get('/api/payments/sequentials/')
    const response = { loading: false }
    if (res.status === 200)
        response.sequences = res.data
    setState(response)

}


const theme = createTheme({
    palette: {
        primary: { main: "#21446C" },
    },
}, ptBR, corePtBR)

const columns = (setState) => {
    return [
        { field: 'sequential', headerName: 'Configuração', flex: .2 },
        { field: 'value', headerName: 'Valor', flex: .8, renderCell: ({value}) => <Tooltip title={value}><Box>{value}</Box></Tooltip> },
        // {
        //     field: 'actions', headerName: 'Ações', renderCell: ({ row }) => (
        //         <Box>
        //             <Tooltip title='Editar sequência'>
        //                 <Button className="action-button"
        //                     onClick={() => setState({ edit: row })}>
        //                     <EditIcon />
        //                 </Button>
        //             </Tooltip>
        //         </Box>
        //     )
        // }
    ]
}

const submit = async (data, handleClose, setLoading, updateData) => {

    setLoading(true)
    const res = await api.patch('/api/payments/sequentials/', data)
    setLoading(false)
    if (res.status === 200) {
        handleClose()
        updateData()
    }
}

const EditSequenceComponent = ({ data, handleClose, setLoading, updateData }) => {

    const [state, setState] = useReducer(lockedReducer, {
        value: data.value,
        sequential: data.sequential
    })

    return (
        <Dialog fullWidth maxWidth="sm" open={true} >
            <DialogTitle className={'group-flexbox'}>
                <span>Editar configuração: {data.sequential}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose} ><CloseIcon /></Button>
            </DialogTitle>
            <Divider />
            <Loading loading={state.loading} />
            <DialogContent>
                <TextField
                    type="sequence"
                    label="Valor"
                    size='small'
                    value={state.value}
                    onChange={(event) => setState({ value: IntegerOnly(event.target.value) })}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => submit(state, handleClose, setLoading, updateData)}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

const SequencesComponent = () => {

    const [state, setState] = useReducer(lockedReducer, {
        sequences: [],
        edit: null,
        loading: false
    })

    useEffect(() => {
        initialize(setState)
    }, [])

    return (
        <Box className="plans-container">
            <Loading loading={state.loading} />
            {state.edit && <EditSequenceComponent
                data={state.edit}
                handleClose={() => setState({ edit: null })}
                setLoading={(value) => setState({ loading: value })}
                updateData={()=>initialize(setState)}
            />}
            <ThemeProvider theme={theme}>
                <DataGridPro
                    rows={state.sequences}
                    columns={columns(setState)}
                    density={"compact"}
                    pagination
                    paginationMode="client"
                    pageSize={20}
                    rowsPerPageOptions={[20, 50, 100]}
                    disableSelectionOnClick
                />
            </ThemeProvider>
        </Box>
    )
}


export default SequencesComponent