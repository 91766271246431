import React, { useReducer } from 'react'
import defaultReducer from '../../../../utils/defaultReducer'

import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Divider, Button, Select, MenuItem, InputLabel, FormControl, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close'
import { mimeTypes } from './mimetypes';
import IntegerOnly from '../../../../utils/IntegerOnly'
import { iconDictionary } from '../../../Chat/MessagesArea/ChatWindow/MessageItem/attachment';

const EditAttachments = ({ data, parent, setParent, handleClose }) => {

    const [state, setState] = useReducer(defaultReducer,
        {
            key: 0,
            max_size: 64000,
            active: true,
            icon: 0,
            direction: 'both',
            ...data
        })

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}
            disablePortal
            disableEnforceFocus
            sx={{ position: 'absolute' }}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle className="group-flexbox">
                {data.id ? 'Editar anexo' : 'Adicionar Anexo'}
                <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <Divider />
            <DialogContent className="add-template-dialog-content" >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                    <Box className='BuilderBotSelectorBox'>
                        <Button variant={!state.active ? 'standard' : 'contained'} onClick={() => setState({ active: true })}>Ativo</Button>
                        <Button variant={state.active ? 'standard' : 'contained'} onClick={() => setState({ active: false })}>Inativo</Button>
                    </Box>
                    <Box sx={{display:'flex', gap:'1rem'}}>
                        <FormControl
                            fullWidth
                            size="small"
                            sx={{flex: 1}}
                        >
                            <InputLabel id="attachments-label">Extensão</InputLabel>
                            <Select
                                value={state.key}
                                onChange={(e) => setState({ key: e.target.value })}
                                labelId='attachments-label'
                                label='Extensão'
                            >
                                <MenuItem key={`select-0-mimetypes`} value={0}>Selecione*</MenuItem>
                                {Object.entries(mimeTypes).filter(([key, ]) => !(key in parent)).map(([key, value], index) => (
                                    <MenuItem key={`${key}-${index}-mimetypes`} value={key}>{value.replace('.', '') + ' ' +key}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <TextField
                            sx={{flex: 2}}
                            size='small'
                            label='Tamanho máximo (kB)'
                            value={state.max_size}
                            onChange={(e) => setState({ max_size: IntegerOnly(e.target.value, 0, 64000) })}
                        />
                        <FormControl
                        sx={{flex: 1}}
                            fullWidth
                            size="small"
                        >
                            <InputLabel id="icon-label">Ícone</InputLabel>
                            <Select
                            size='small'
                                value={state.icon}
                                onChange={(e) => setState({ icon: e.target.value })}
                                labelId='icon-label'
                                label='Ícone'
                            >
                                <MenuItem  key={`select-0-icon`} value={0}>Selecione*</MenuItem>
                                {Object.entries(iconDictionary).map(([key, value], index) => (
                                    <MenuItem  key={`${key}-${index}-icon`} value={key}><Box sx={{display:'flex'}}>{value}</Box></MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Box>
                    <Box className='BuilderBotSelectorBox'>
                        <Button variant={state.direction === 'both' ? 'contained' : 'standard'} onClick={() => setState({ direction: 'both' })}>Receber e enviar</Button>
                        <Button variant={state.direction === 'inbound' ? 'contained' : 'standard'} onClick={() => setState({ direction: 'inbound' })}>Apenas receber</Button>
                        <Button variant={state.direction === 'outbound' ? 'contained' : 'standard'} onClick={() => setState({ direction: 'outbound' })}>Apenas enviar</Button>
                    </Box>
                </Box>

            </DialogContent>
            <DialogActions >
                <Box className="user-footer">
                    <LoadingButton className="button-created"
                    disabled={state.extension === 0 || state.icon === 0}
                        onClick={() => {
                            setParent({ ...parent, [state.key]:state })
                            handleClose()
                        }}
                    >
                        Salvar
                    </LoadingButton>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default EditAttachments