import React, { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tabs,
  Tab,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { transformTimeData } from "../ChatList";
import { parseMessage } from "../ScoutMessageComponent";

import dayjs from "dayjs";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function ReactionCard(props) {
  const { name, picture, phoneNumber } = props;

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={name} src={picture} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box className={`scout-title-header`}>
            <span className="scout-preview-message">{name}</span>
          </Box>
        }
        secondary={phoneNumber}
      />
    </ListItem>
  );
}

const ReactionsList = ({ data = {}, handleClose }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={true}
      fullWidth
      disablePortal
      disableEnforceFocus
      maxWidth={"md"}
      sx={{ position: "absolute" }}
    >
      <DialogTitle className="group-flexbox">
        <Button sx={{ minWidth: "2rem", padding: "0" }} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {data.k.map((key, index) => (
                <Tab label={key} key={index} />
              ))}
            </Tabs>
          </Box>
          {data.k.map((key, index) => (
            <CustomTabPanel value={value} index={index}>
              {data.r[key].messages.map((message, index) => (
                <ReactionCard
                  key={index}
                  name={message.message.contactData.name}
                  phoneNumber={message.message.contactData.phoneNumber}
                  picture={message.message.contactData.picture}
                />
              ))}
            </CustomTabPanel>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReactionsList;
