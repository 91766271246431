import React from "react";
import { Box, IconButton, Tooltip, Divider, Button } from "@mui/material";
import { Alert } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import TransferIcon from '@mui/icons-material/CompareArrows';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import PhoneCallIcon from '@mui/icons-material/Phone';
import CopyIcon from '@mui/icons-material/ContentCopy';
import AgentIcon from '@mui/icons-material/SupportAgent';
import CloseChatIcon from '@mui/icons-material/PowerSettingsNew';
import ArrowBack from '@mui/icons-material/ArrowBack';
import isMobile, { lowResolution } from "../../../../utils/isMobile";

import AvatarName from "../../../AvatarName";
import Typography from "../../../Typography";
import ConfirmationDialog from "../../../Dialogs/Confirmation";
import HangupDialog from "../../../Dialogs/Hangup";
import TransferDialog from "../../../Dialogs/Transfer";
import PredefinedMessageDialog from "../../../Dialogs/PredefinedMessageDialog";
import SendTemplateDialog from "../../../Dialogs/SendTemplateDialog";

import useNotification from "../../../Notification";

import MessageArea from "./MessageArea";
import Footer from "./Footer";
import EmailFooter from "./EmailFooter";
import AttachmentArea from "./AttachmentArea";

import api from '../../../../api';
import { getSession } from "../../../../auth";
import MemoMessage from "./MessageArea/MemoMessage";
import './styles.css';
import ClientDialog from "../../../Dialogs/Client";
import formatPhone from "../../../../utils/format_phone"
import { mimeTypes } from "../../../Settings/Email/AttachmentsDialog/mimetypes";
import NewMessageArea from "./NewMessageArea";

const delayed_send_message_to_socket = async (chat, message, retry=3) => {
    if (retry === 0) return
    try{
        chat.socket?.send(JSON.stringify({
            'channel': chat.channel,
            'message': JSON.stringify(message),
            'user': -2
        }))
    } catch (e) {
        setTimeout(()=>delayed_send_message_to_socket(chat,message,retry-1), 1000)
    }

}

class ChatWindow extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            file: {},
            messages: [],
            transferError: null,
            showConfirmationDialog: false,
            showPredefinedMessages: false,
            showTransferDialog: false,
            chatWantsReactivate: false,
            transferChatButtonLoading: false,
            showContactCreate: false,
            userModel: {},
            callMessage: '',
            ioSubscribed: false,
            alreadyConnected: false,
            dialNumber: null,
            chat_blocked: true,
            replying: null
        }

        this.webphoneKeypadKeysMapper = {
            '1': 0,
            '2': 1,
            '3': 2,
            '4': 3,
            '5': 4,
            '6': 5,
            '7': 6,
            '8': 7,
            '9': 8,
            '*': 9,
            '0': 10,
            '#': 11
        };

        this.contact = null;
    }

    componentDidMount() {
        if (this.props.isManageCalls)
            this.props.connectWebSocket();

        window.reloadMessageArea = (messages) => this.setState({ messages: messages })
    }

    componentWillMount() {
        this.state.messages = this.props.activeChat.messages;
        window.blockChat = (e) =>{this.setState({ chat_blocked: e })}

        if (window.socketVoice) {
            if (!window.ioSubscribed) {
                window.socketVoice.on('call-event', this.onCallEvent);
                window.ioSubscribed = true;
            } else {
                window.socketVoice._callbacks["$call-event"] = [this.onCallEvent]
            }
        }
        this.getChatLabels();
    }

    componentWillUnmount() {
        window.chatBlock = (e) => { }
    }

    componentDidUpdate(prevProps, prevState) {
        // const newMessages = this.state.messages?.length - prevState.messages?.length

        // if (newMessages)
        //     this.messageArea.addToMessageAmount(this.state.messages)

    }

    refreshChat() {
        this.setState({ messages: this.props.activeChat.messages })
    }

    reloadContact = async () => {
        let activeChat = this.props.activeChat
        activeChat.contact = await this.getDataContact(true)
        this.props.setActiveChat(activeChat)
    }

    showProfile = async () => {
        await this.getDataContact(true);
    }

    getDataContact = async (showProfile) => {
        if (lowResolution())
            return;

        if (showProfile)
            this.props.setLoading(true)

        let nick = this.props.activeChat.callerid

        // Handle instantchat email or phone if existing
        if (this.props.activeChat.platform === 'INSTANTCHAT')
            // Why do we need to retrieve contact again if we already have it ?!
            if (this.props.activeChat.contact) {
                nick = (this.props.activeChat.contact.telephone) 
                    ? `phone@@${this.props.activeChat.contact.telephone}` 
                    : (this.props.activeChat.contact.email)
                        ? `email@@${this.props.activeChat.contact.email}`
                        : `nick@@${nick}`
            } else {
                nick = (this.props.activeChat.phone) 
                    ? `phone@@${this.props.activeChat.phone}` 
                    : (this.props.activeChat.email)
                        ? `email@@${this.props.activeChat.email}`
                        : `nick@@${nick}`   
            }

        // Add platform to start of nick
        nick = `${this.props.activeChat.platform}@@@${nick}`

        let contact = null

        // Both separators @@ and @@@ are used to split and recognize data in
        // order to properly retrieve contact for all channels
        await api.get(`api/enterprises/${this.props.enterpriseId}/contacts/retrieve/${nick}/`)
            .then(async (res) => {
                contact = res.data
                if (showProfile) {
                    this.props.setLoading(false);
                    if (res.status === 200)
                        this.props.showChatRightSideBar(res.data);
                    if (res.status === 404) {
                        if ('detail' in res.data && res.data.detail == 'Not found.') {
                            if (this.props.activeChat.platform !== 'INSTANTCHAT') {
                                const response = await this.insertContact(this.props.activeChat)
                                if (response === 201)
                                    this.getDataContact(showProfile)   
                            } else {
                                useNotification(
                                    'Atenção!',
                                    'Não é possível visualizar informações de contato deste canal de atendimento.',
                                    'warning'
                                );
                            }
                        } else {
                            useNotification(
                                'Múltiplos!',
                                '2 ou mais contatos foram encontrados com este número de telefone.',
                                'warning'
                            );
                        }
                    }
                    return;
                }

                if (res.status === 200) {
                    this.contact = res.data;
                    return;
                }

                if (res.status === 404) {
                    // Setting null or multiple from API response
                    this.contact = res.data;
                    return;
                }

                throw res;
            })
            .catch((err) => {
                console.error(err);
            })

        return contact
    }

    copyDataContact = () => {
        if (this.props.activeChat.contact) {
            const contact = this.props.activeChat.contact

            let textToCopy = '';
            const nome = contact.name || null;
            const email = contact.email || null;
            const telephone = contact.telephone || null;

            if (nome) {
                textToCopy += `${nome}`;

            } if (email) {
                if (nome)
                    textToCopy += ', ';

                textToCopy += `${email}`;

            } if (telephone) {
                if (nome || email)
                    textToCopy += ', ';

                textToCopy += `${telephone}`;
            }

            navigator.clipboard.writeText(textToCopy);

            useNotification(
                'Sucesso!',
                'Informações copiadas com sucesso.',
                'success'
            );
        } else {
            useNotification(
                'Erro!',
                'Informações não encontradas.',
                'danger'
            );
        }
    }

    isWhatsappSessionOpen = () => {
        /** If the client's last message has more than 24hrs
         * from the current timestamp, WhatsApp session has
         * ended, and the agent can only send a message to the
         * client by choosing one of the enterprise's approved 
         * templates **/

        let last_message = this.props.activeChat.last_message;
        let last_client_message = this.props.activeChat.last_user_message || this.props.activeChat.last_user_msg_time;

        if (!last_client_message) {
            // No client messages, no session
            return false;
        }

        const time = last_client_message?.time || last_client_message;
        const curTime = Math.floor(Date.now() / 1000);

        return (curTime - time < 86400);
    }


    finishChat = async (extraData = [], message = null) => {
        if (!this.props.agentId) return;
        this.props.setLoading(true);

        const user = getSession();
        const reactivation = ((this.shouldShowPendingFooter() && !this.props.activeChat.notification) || this.isPendingTab())

        if (reactivation) {
            const res = await api.delete(`api/enterprises/${this.props.enterpriseId}/reactivation/${this.props.activeChat.id}/`, { timeout: 0 })

            this.props.setActiveChat({});
            this.props.closeWebSocket();
            this.props.setLoading(false);

            if (this.props.isManageCalls)
                this.props.closeModal();

            if (res.status !== 204) {
                useNotification(
                    'Ops!',
                    `Ocorreu um erro com a requisição.`,
                    'warning')
            }
            return
        }

        const chat = this.props.activeChat
        const queue_name = chat.queues_name ? chat.queues_name : chat.queue?.name
        const channel = chat.channel ? chat.channel : chat.hash
        const connection = this.props.connections?.find(con => con.queue == queue_name)

        const body = {
            channel   : this.props.activeChat.channel,
            unique_id : this.props.activeChat.uniqueid,
            callerid  : this.props.activeChat.callerid
        }

        if (user.profile.enterprise.settings.agent_identification && user.profile.enterprise.settings.isAgentIdentificated)
            body['agentName'] = user.profile.enterprise.settings.isAgentIdentificated

        if (extraData.length)
            body['extra_data'] = extraData

        if (message)
            body['message'] = message

        let url = `api/IV/agents/${this.props.agentId}/hangup/`
        if (this.isActiveChatBot() || this.isActiveChatOnQueue())
            url = `api/IV/agents/${this.props.agentId}/bot_hangup/`

        const res = await api.post(url, body)

        if (res.status == 200 || res.status == 204) {

            if (this.props.activeChat.socket) {
                const activechat = this.props.activeChat
                activechat.socket?.send(JSON.stringify({
                    'channel': activechat.channel ? activechat.channel : activechat.hash,
                    'message': JSON.stringify({ chatID: activechat.id, actor: user.profile.agent.name }),
                    'user': -5
                }))
            }

            this.props.setActiveChat({});
            this.props.closeWebSocket();

            if (queue_name && connection)
                connection.send(JSON.stringify({ message: "HANGUP", channel: channel }))

            if (this.props.isManageCalls)
                this.props.closeModal();
            else
                this.props.removeOngoingChat(this.props.activeChat);

            this.setState({ showConfirmationDialog: false });

            if (window.closeExternalId && typeof window.closeExternalId === 'function') {
                window.closeExternalId(chat.uniqueid)
            }

            if (window.canSendMessage && typeof window.canSendMessage === 'function') {
                window.canSendMessage(false)
            }
        } else if (res.status === 503) {
            await useNotification(
                'Manutenção!',
                'Estamos em manutenção, tente novamente mais tarde.',
                'warning'
            );
        }

        this.props.setLoading(false);
    }

    //I did not found the exposed path to kwikchat, so i routed the command to server and handled there.
    //We don't even need to wait.
    addNewChat = async (queueName, channel) => {
        const url = `api/enterprises/${this.props.enterpriseId}/calls/chat/transfer/${queueName}/${channel}/`
        api.get(url)
    }

    transferChat = async (queueName) => {
        await this.props.setLoading(true);
        this.setState({
            transferError: null,
            transferChatButtonLoading: true
        });

        let url = `api/IV/agents/${this.props.agentId}/transfer/`
        if (this.isActiveChatBot())
            url = `api/IV/agents/${this.props.agentId}/bot_transfer/`

        api.post(url, {
            queue: queueName,
            unique_id: this.props.activeChat.uniqueid
        }).then(async (response) => {
            await this.props.setLoading(false);

            this.setState({
                transferChatButtonLoading: false
            });

            if (this.isActiveChatBot()) {
                const r = response.data.pop();
                if (r == 'Internal Server Error') {
                    this.setState({ transferError: 'Erro ao tentar transferir chat. Por favor, entre em contato com o suporte Kwik. Código: 500' });
                    return;
                }
                const resp = JSON.parse(r);

                if (resp.status_code == 400) {
                    useNotification(
                        'Ops!',
                        `O comando de forçar transferência não está configurado em seu BOT. Entre em contato com o suporte para utiliza-lo.`,
                        'warning')
                    return
                }

            }

            if (response.status == 400) {
                this.setState({ transferError: response.data });
                return;
            }

            //We should drop the chat from the previous queue
            const chat = this.props.activeChat
            const queue_name = chat.queues_name ? chat.queues_name : chat.queue?.name
            const channel = chat.channel ? chat.channel : chat.hash
            const connection = this.props.connections?.find(con => con.queue == queue_name)
            //We should remove chat from older queue

            if (queue_name && connection && channel) {
                connection.send(JSON.stringify({ message: "DROP_CHAT", channel: { channel: chat.channel, user: getSession().profile.agent.name } }))
                //We should add chat to new queue using webhook ?
                this.addNewChat(queueName, channel)
            }

            if (this.props.activeChat.socket) {
                const activechat = this.props.activeChat
                const user = getSession()
                const queueDescription = user.profile?.queues?.find(queue => queue.name == queueName)?.description
                activechat.socket?.send(JSON.stringify({
                    'channel': activechat.channel ? activechat.channel : activechat.hash,
                    'message': JSON.stringify({
                        chatID: activechat.id,
                        actor: user.profile.agent.name,
                        queue: queueDescription ? queueDescription : 'Desconhecida',
                        last_queue: activechat.queue ? activechat.queue.description : 'Desconhecida',
                    }),
                    'user': -4
                }))
            }

            this.props.setActiveChat({});
            this.props.closeWebSocket();

            if (this.props.isManageCalls) {
                this.props.closeModal();
            } else {
                this.props.removeOngoingChat(this.props.activeChat);
            }

            this.setState({
                showTransferDialog: false,
            });
        }).catch((err) => {
            console.error(err);
        });
    }

    isActiveChatOnQueue = () => {
        return this.props.activeChat.chatstatus == 'QUEUE';
    }

    isActiveChatFinished = () => {
        return !('chatstatus' in this.props.activeChat);
    }

    contactExists = (activeChat) => {
        return ((this.contact && 'detail' in this.contact && this.contact.detail != 'Not found.' && this.contact.detail == 'Não encontrado.') || activeChat.contact)
    }

    connectActiveChat = async (email = false) => {
        if (!this.props.agentId) return;

        let status = false;
        const user = getSession()

        const body = {
            channel   : this.props.activeChat.channel,
            unique_id : this.props.activeChat.uniqueid,
            agentName : user?.profile?.agent?.name
        }

        if (user.profile.enterprise.settings.agent_identification && user.profile.enterprise.settings.isAgentIdentificated)
            body['agentName'] = user.profile.enterprise.settings.isAgentIdentificated

        const res = await api.post(`api/IV/agents/${this.props.agentId}/connect/`, body)

        if (res.status === 200) {
            this.props.activeChat.chatstatus = 'ONCHAT';
            this.props.activeChat.agent = getSession().profile.agent

            if (!this.contactExists(this.props.activeChat)) {
                if (this.props.activeChat.platform !== 'INSTANTCHAT')
                    this.insertContact(this.props.activeChat);
            }

            if (!this.props.isManageCalls) {
                if (!email)
                    this.props.setActiveTab(1);
                this.props.addOngoingChat();
                this.props.setReadMessages(this.props.activeChat.messages);
            }

            this.props.setActiveChat({ ...this.props.activeChat })
            return true
        }
        else if (res.status !== 200) {
            if (res.status === 503) {
                await useNotification(
                    'Manutenção!',
                    'Estamos em manutenção, tente novamente mais tarde.',
                    'warning'
                );
            } else if (res.status === 404) {
                await useNotification(
                    'Inexistente!',
                    'Este chat foi desligado por outro operador.',
                    'warning'
                );
            } else if (res.data == 'Maximum ongoing chats reached.' || res.data == 'Limite de atendimentos simultâneos atingido.') {
                await useNotification(
                    'Limite de chats atingido!',
                    'Você atingiu o limite máximo de chats em andamento.',
                    'warning'
                );
            } else if (res.data == 'Chat atendido por outro operador.') {
                await useNotification(
                    'Atendido!',
                    'Este chat foi atendido por outro operador.',
                    'warning'
                );
            } else {
                await useNotification(
                    'Erro!',
                    'Houve um erro na requisição, tente novamente.',
                    'danger'
                );
            }
        }

        return false;
    }

    insertContactChatweb = async (name, email, telephone, userFields, advisor, enterpriseName, enterpriseCNPJ) => {
        const formatTelephone = formatPhone(telephone);
        const chat = this.props.activeChat

        if (!formatTelephone) {
            useNotification(
                'Atenção!',
                'Telefone é um campo obrigatório.',
                'warning'
            );
            return;
        }

        const res = await api.post(`/api/enterprises/${this.props.enterpriseId}/contacts/`, {
            // Setting default nick as telephone for WhatsApp notifications
            nick: formatTelephone,
            name: name,
            email: email,
            telephone: formatTelephone,
            user_fields: userFields,
            advisor: advisor,
            enterprise_name: enterpriseName,
            enterprise_cnpj: enterpriseCNPJ,
            hash: chat.channel ? chat.channel : chat.hash,
            fromchat: true,
            registerChat: true
        })

        if (res.status === 201) {
            this.contact = res.data;
            chat.contact = this.contact
            chat.callerid = res.data.nick
            this.setState({ showContactCreate: false })
            this.getDataContact(true)
            useNotification(
                'Sucesso!',
                'Cliente cadastrado com sucesso!',
                'success'
            );
        }
        else if (res.status === 206) {
            this.contact = res.data;
            chat.contact = this.contact
            chat.callerid = res.data.nick
            useNotification(
                'Sucesso!',
                'Cliente já cadastrado.',
                'success'
            );
            this.setState({ showContactCreate: false })
            this.getDataContact(true)
        }
        console.error(res.err);
    }

    insertContact = async (chat) => {

        const payload = {
            'nick': chat.callerid,
            'hash': chat.channel ? chat.channel : chat.hash,
            'platform': chat.platform,
            'fromchat': true
        }

        switch (chat.platform) {
            case 'TELEGRAM':
                payload['telegram_id'] = chat.callerid
                break
            case 'WHATSAPP_ATIVO':
            case 'WHATSAPP':
                payload['telephone'] = chat.callerid
                break
            case 'MSTEAMS':
                payload['msteams_id'] = chat.callerid
                break
            case 'INSTAGRAM':
                payload['instagram_id'] = chat.callerid
                break
            case 'EMAIL':
                payload['email'] = chat.callerid
                break
            case 'MESSENGER':
                payload['messenger_id'] = chat.callerid
                break
            default:
                payload['name'] = chat.lhc ? chat.lhc.nick : chat.nick,
                    payload['email'] = chat.lhc ? chat.lhc.email : chat.email,
                    payload['telephone'] = chat.lhc ? chat.lhc.phone : chat.phone
                break
        }

        const res = await api.post(`api/enterprises/${this.props.enterpriseId}/contacts/`, payload)

        if (res.status === 201)
            this.contact = res.data;

        return res.status
    }

    /**
     *  What happens when we send a message?
     *  if chat is onqueue, we should broadcast the now obrigatory 
     * ''CONNECTION'' that we belong which is related to accountcode 
     * to all channels 
     **/
    sendMessage = async (message, email = false, attachments = null) => {
        const chat = this.props.activeChat
        const queue_name = chat.queues_name ? chat.queues_name : chat.queue?.name
        const channel = chat.channel ? chat.channel : chat.hash
        const connection = this.props.connections?.find(con => con.queue == queue_name)

        if (message !== ''){
            if (this.isActiveChatFinished()) {
                let reactivate = null
                if (this.isWhatsAppChat()){
                    reactivate = await this.reactivateChat(message);
                } else if (this.isEmailChat()){
                    reactivate = await this.reactivateEmail(message, attachments);
                }
                if (reactivate && queue_name && connection)
                    connection.send(JSON.stringify({
                        message: "REACTIVATE",
                        channel: channel
                    }))
                return
        }
        const res = await this.postMessage(message, undefined, email, attachments)

        if (res === -1) return

        if (this.isActiveChatOnQueue()) {
            const result = await this.connectActiveChat(email)
            if (!result)
                return;
            if (queue_name && connection && channel)
                connection.send(JSON.stringify({
                    message: "ONCHAT",
                    channel: channel
                }))
        }


        return res;

        }
    }

    reactivateEmail = async (message, attachments=null) => {
        this.props.setLoading(true);

        if (attachments?.length) {
            message = await this.postEmailAttachments(message, attachments)
            if (message === -1){
                this.props.setLoading(false);
                return -1
            }
        }

        const user = getSession()

        let messages = this.state.messages;

        const last_user_message = messages.findLast(each => each.user_id != 0)

        const pre_message = {
            "id"           : 0,
            "message"      : message,
            "iso_time"     : "--:--",
            "msg"          : message,
            "time"         : last_user_message?.time,
            "user_id"      : getSession().profile.agent.id,
            "name_support" : last_user_message?.name_support,
            "is_read"      : true,
            "req_input"    : last_user_message?.req_input,
            "chat"         : last_user_message?.chat
        }

        this.handleAddMessage(pre_message)

        const res = await api.post(`api/enterprises/${this.props.enterpriseId}/reactivation/email/`, {message:message, chat:this.props.activeChat})
        this.props.setLoading(false);
        if (res.status === 200){

            pre_message.id = res.data.id
            pre_message.iso_time = res.data.iso_time
            pre_message.user_id = user.id
            pre_message.time = res.data.time
            this.handleAddMessage(user.profile.enterprise.infinite_scroll ? pre_message : null)
            delayed_send_message_to_socket(this.props.activeChat, res.data)

            useNotification(
                'Sucesso!',
                'E-mail enviado!',
                'success'
            );
        } else {
            useNotification(
                'Ops!',
                'O e-mail não pôde ser enviado.' + ' ' + res.data,
                'danger'
            );
        }


    }

    reactivateChat = async (message) => {
        this.setState({ chatWantsReactivate: true });
        this.props.setLoading(true);

        let that = this;

        const res = await api.post('api/whatsapp/reactivation/', {
            'from': this.props.activeChat.sender,
            'to': this.props.activeChat.callerid,
            'chat_id': this.props.activeChat.id,
            'queue_name': this.props.activeChat.queue?.name?.split('_').pop(),
            'agent': getSession().profile.agent.user.split('_').pop(),
            'message': message
        })

        if (res) {
            this.setState({ chatWantsReactivate: false });
            this.props.setLoading(false);
            if (res.status == 201) {
                await that.finishReactivation(res.data);
                useNotification(
                    'Chat Reativado!',
                    'Aguarde a resposta do cliente, ao responder, ele será redirecionado automaticamente para sua lista de atendimentos.',
                    'info'
                );
                return true;
            }
            else if (res.status == 400) {
                if (res.data == 'Sandbox environment not allowed.') {
                    useNotification(
                        'WhatsApp Sandbox!',
                        'Você não pode reativar uma conversa realizada via sandbox.',
                        'danger'
                    );
                }
                else if (res.data == 'Client has an ongoing reactivation.') {
                    useNotification(
                        'Ops!',
                        'O cliente tem uma reativação em andamento.',
                        'danger'
                    );

                } else if (res.data == 'Client has a queued or ongoing chat.') {
                    useNotification(
                        'Ops!',
                        'Você não pode reativar este atendimento. O cliente tem um chat em andamento.',
                        'danger'
                    );

                }
                else if (res.data == 'Message contains restricted words') {
                    useNotification(
                        'Mensagem inapropriada!',
                        'A mensagem não foi enviada pois contém uma ou mais palavras restritas',
                        'danger'
                    );
                }
                return;
            }

        }
        return false
    }

    finishReactivation = async (message) => {

        if (isMobile())
            // Avoid mobile errors
            this.props.setActiveChat({});

        if (!this.props.isManageCalls) {
            this.props.setActiveTab(2);
            this.props.setActiveSubTab(0)
        }

        this.props.addReactivatedChat(this.props.activeChat);

        //Removed due to be unecessary
        //this.props.activeChat.chatstatus = 'PENDING';

        let messages = this.state.messages;
        messages.push(message);

        this.setState({ messages: messages });
        return true
    }

    handleAddMessage = (newMsg) => {
        if (getSession().profile.enterprise.infinite_scroll)
            this.messageArea.handleAddNewMessage(newMsg)
        else {
            this.messageArea.setState({ messages: [...this.messageArea.state.messages] })
            if (newMsg) {
                this.setState({ messages: [...this.state.messages, newMsg] })
                return
            }
            this.setState({ messages: [...this.state.messages] })
        }
    }

    invokeMessageArea = () => {


        return (
            <>
                {
                    this.state.messages &&
                    <ul>
                        {this.state.messages.map((message, i) =>

                            // <Message key={i} message={message.message} />
                            <MemoMessage
                                message={message}
                                index={i}
                                accountcode={this.props.accountcode}
                                isManageCalls={this.props.isManageCalls}
                                queuesName={this.queuesName}
                                enterpriseId={this.props.enterpriseId}
                                activeChat={this.props.activeChat}
                                setReplying={(message) => this.setState({ replying: message })}
                            />
                        )
                        }
                    </ul>
                }
            </>
        )

    }

    postEmailAttachments = async (message, attachments) => {
        const data = new FormData()
            attachments.map(each => data.append('files', each))
            data.append('uniqueid', this.props.activeChat.uniqueid)
            const res = await api.post('api/LHC/messages/attachments/', data)
            if (res.status === 200) {
                message += res.data.join('')
            }
            else if (res.status === 400) {
                const res_message = JSON.parse(res.data.join(''))
                const type = res_message.type
                const extension = mimeTypes[res_message.type] || res_message.type
                useNotification(
                    'Erro!',
                    res_message.msg.replace(type, extension),
                    'danger'
                );
                return -1
            }
            else {
                useNotification(
                    'Erro!',
                    'Ocorreu um erro ao importar os anexos.',
                    'danger'
                );
                return -1
            }

            return message
    }

    postMessage = async (message, showToClient, email = false, attachments = null) => {

        // Add users' nickname or fullname to message if whatsapp
        if (getSession().profile.enterprise.settings.save_nickname && this.props.activeChat.platform !== 'EMAIL') {
            const nickname = getSession().profile.nickname
            const fullname = getSession().first_name + ' ' + getSession().last_name
            message = `${nickname ? nickname : fullname}:\n${message}`
        }

        if (email && attachments?.length) {
            message = await this.postEmailAttachments(message, attachments)
            if (message === -1){
                return -1
            }
                
        }
        //if email && attachments.length > 0
        // upload attachments first
        //then add this attachments urls to the message as (emailattachment)(/emailattachment)
        //on backend, use this urls to form the

        let that = this;
        const user = getSession()

        let messages = this.state.messages;

        const last_user_message = messages.findLast(each => each.user_id != 0)

        let show_to_client = (showToClient === undefined) ? 1 : showToClient;

        const pre_message = {
            "id"           : 0,
            "message"      : message,
            "iso_time"     : "--:--",
            "msg"          : message,
            "time"         : last_user_message?.time,
            "user_id"      : getSession().profile.agent.id,
            "name_support" : last_user_message?.name_support,
            "is_read"      : true,
            "req_input"    : last_user_message?.req_input,
            "chat"         : last_user_message?.chat
        }

        this.handleAddMessage(pre_message)

        const async_response = api.post('api/LHC/messages/', {
            message        : message,
            chat_hash      : this.props.activeChat.channel || this.props.activeChat.hash,
            unique_id      : this.props.activeChat.uniqueid,
            queue_id       : this.props.activeChat.queue.id,
            is_read        : !this.props.isManageCalls,
            show_to_client : show_to_client,
            reply_from     : this.state.replying?.message_id
        }).then(async (res) => {
            this.messageArea?.dragScrollDownEffect()
            if (res.status !== 201) {
                pre_message.id = -2
                pre_message.expired = true
                pre_message.time = Math.floor(Date.now() / 1000)
                this.handleAddMessage(user.profile.enterprise.infinite_scroll ? pre_message : null)

            }

            if (res.status == 201) {
                pre_message.id = res.data.id
                pre_message.iso_time = res.data.iso_time
                pre_message.user_id = res.data.user_id
                pre_message.time = res.data.time
                this.handleAddMessage(user.profile.enterprise.infinite_scroll ? pre_message : null)
                delayed_send_message_to_socket(this.props.activeChat, res.data)
                this.setState({ replying: null })
                // Closing all email chats if answered
                if (email === true)
                    this.finishChat()
                return 1;
            } else if (res.status == 503) {
                await useNotification(
                    'Manutenção!',
                    'Estamos em manutenção, tente novamente mais tarde.',
                    'warning'
                );
                return -1;
            } else if (res.status == 400) {
                if (res.data == 'Session closed') {
                    await useNotification(
                        'Sessão encerrada!',
                        'Você não pode mais enviar mensagens para esta conversa',
                        'danger'
                    );
                    return -1;
                } else if (res.data == 'Queue does not exist') {
                    await useNotification(
                        'Fila não encontrada!',
                        'A mensagem não foi enviada pois a fila do chat não foi encontrada pelo sistema',
                        'danger'
                    );
                    return -1;
                } else if (res.data == 'Message contains restricted words') {
                    await useNotification(
                        'Mensagem inapropriada!',
                        'A mensagem não foi enviada pois contém uma ou mais palavras restritas',
                        'danger'
                    );
                    return -1;
                } else if (res.data == 'Message contains URL') {
                    await useNotification(
                        'Mensagem inapropriada!',
                        'A mensagem não foi enviada pois contém uma URL.',
                        'danger'
                    );
                    return -1;
                } else if (res.data == 'lh_msg id not exist') {
                    await useNotification(
                        'Chat com problemas!',
                        'Favor desligue o chat e tente reativá-lo.',
                        'danger'
                    )
                    return -1
                }
            } else if (res.status == 404) {
                await useNotification(
                    'Chat não encontrado!',
                    'Este chat foi finalizado pelo sistema',
                    'danger'
                );
                return -1;
            }
            throw res;
        }).catch(async (err) => {
            useNotification(
                'Ops!',
                `Ocorreu um erro no sistema`,
                'danger'
            );
            return -1
        });

        return async_response
    }

    showPredefinedMessages = () => {
        this.setState({
            showPredefinedMessages: true
        });
    }

    replaceMessage = (message) => {
        let newMessage = message

        const replaceTable = {

            "{{nome_cliente}}": this.setContactNick,
            "{{nome_operador}}": this.setAgentName,
            "{{codigo_cliente}}": this.setCustomerCode
        }

        Object.entries(replaceTable).some(([key, value]) => {
            if (newMessage.includes(key))
                newMessage = newMessage.replaceAll(key, value())
        })

        return newMessage
    }

    usePredefinedMessage = (message) => {
        this.footer.setText(this.replaceMessage(message));
    }

    setFile = (file) => this.setState({ file: file })

    transferDialog = () => {
        const user = getSession();

        if (user.profile.enterprise.name != 'POC Instant')
            this.setState({ showTransferDialog: true });
        else
            useNotification('Atenção!', 'Não é possível transferir o atendimento em modo Trial.', 'warning');
    }

    isActiveChatPending = () => {
        return this.props.activeChat.chatstatus === 'PENDING';
    }

    isActiveChatOnChat = () => {
        return this.props.activeChat.chatstatus === 'ONCHAT';
    }

    isActiveChatBot = () => {
        return this.props.activeChat.chatstatus === null;
    }

    isWhatsAppChat = () => {
        return this.props.activeChat.platform === 'WHATSAPP';
    }

    isTelegramChat = () => {
        return this.props.activeChat.platform === 'TELEGRAM';
    }

    isInstagramChat = () => {
        return this.props.activeChat.platform === 'INSTAGRAM';
    }

    isMessengerChat = () => {
        return this.props.activeChat.platform === 'MESSENGER';
    }

    isMsteamsChat = () => {
        return this.props.activeChat.platform === 'MSTEAMS';
    }

    isEmailChat = () => {
        return this.props.activeChat.platform === 'EMAIL';
    }

    isChatWeb = () => {
        return this.props.activeChat.platform === 'INSTANTCHAT';
    }

    shouldShowFooter = () => {
        return !this.state.chatWantsReactivate
            && this.state.file.preview === undefined
            && !this.isActiveChatPending()
            && !this.isActiveChatBot()
            && (!this.state.chat_blocked || this.isActiveChatFinished())
            && (
                (this.isWhatsappSessionOpen() && this.isWhatsAppChat()) ||
                ((this.isChatWeb() || this.isTelegramChat() || this.isMsteamsChat() || this.isInstagramChat() || this.isMessengerChat()) && (this.isActiveChatOnQueue() || this.isActiveChatOnChat()))
            );
    }

    shouldShowWaitInteractionMessage = () => {
        return this.state.file.preview === undefined
            && !this.isWhatsappSessionOpen()
            && this.isActiveChatOnchat()
    }

    shouldShowReactivationMessage = () => {
        return this.state.file.preview === undefined
            && !this.isActiveChatPending()
            && this.isWhatsAppChat()
            && !this.isWhatsappSessionOpen();
    }

    shouldShowPendingFooter = () => {
        return this.state.file.preview === undefined
            && this.isActiveChatPending();
    }

    shouldShowNotReactivatableFooter = () => {
        return this.state.file.preview === undefined
            && !this.isActiveChatPending()
            && this.isActiveChatFinished()
            && !this.isWhatsAppChat()
            && !this.isEmailChat()
    }

    setContactNick = () => {
        let contactNick = null;

        if ((this.props.contactData?.telephone === this.props.activeChat.callerid) &&
            this.props.contactData?.name)
            contactNick = this.props.contactData.name;
        else if (this.props.activeChat?.contact && this.props.activeChat?.contact?.name)
            contactNick = this.props.activeChat.contact.name;

        return contactNick;
    }

    setCustomerCode = () => {
        let customerCode = null;

        if ((this.props.contactData?.telephone === this.props.activeChat.callerid) &&
            this.props.contactData.customer_code)
            customerCode = this.props.contactData.customer_code;
        else if (this.props.activeChat.contact && this.props.activeChat.contact.customer_code)
            customerCode = this.props.activeChat.contact.customer_code;

        return customerCode;
    }

    setAgentName = () => {
        let agentName = null;

        if (this.props.activeChat.agent && this.props.activeChat.agent.name)
            agentName = this.props.activeChat.agent.name;
        else if (this.props.activeChat.custom_field1_title)
            agentName = this.props.activeChat.custom_field1_title

        return agentName;
    }

    isWhatsappChat = (chat) => {
        return chat.platform === 'WHATSAPP';
    }

    callContact = () => {
        document.getElementById('webphone-icon').click();

        const number = this.props.activeChat?.contact?.telephone?.replace('+55', '0')

        this.state.dialNumber = number;

        if (number) {

            const webphone = document.getElementById('tryit-jssip-container');
            const keypad = webphone.querySelector('.keypad.buttons');
            const backButton = webphone.querySelector('.button.back.no-sub');
            const callButton = webphone.querySelector('.button.call.no-sub');

            if (!webphone || !keypad || !callButton) {
                return;
            }

            // Clear any number from display
            [...Array(15)].forEach(() => backButton.click());

            const keys = keypad.querySelectorAll('.button.digit');
            for (var i = 0; i < number.length; i++) {
                const char = number.charAt(i);
                const charIndex = this.webphoneKeypadKeysMapper[char];
                keys[charIndex].click();
            }

            window.setLink = true;

            callButton.click();

            if (this.props.activeChat && 'chatstatus' in this.props.activeChat && this.props.activeChat['chatstatus'] === 'ONCHAT' && this.props.activeChat.platform !== 'EMAIL')
                this.postMessage("Contato de voz");
        }
    }

    isNotificationTab = () => {
        return (this.props.activeTab == 2 && this.props.activeSubTab == 1 && this.props.activeChat.notification)
    }

    isPendingTab = () => {
        if (this.props.activeChat?.where == "reactivated") return true
        if (this.props.activeTab == 2 && this.props.activeSubTab == 0)
            return true
        return false
    }

    getChatLabels = async () => {

        if (this.props.setChatLabels)
            this.props.setChatLabels(this.props.activeChat.labels)
        // const chat = this.props.activeChat.channel ? this.props.activeChat.channel : this.props.activeChat.hash

        // await api.get(`api/enterprises/${this.props.enterpriseId}/calls/chat/get_chat_labels/${chat}/`)
        // .then((res) => {
        //     if (res.status === 200){
        //         this.props.setChatLabels(res.data)
        //     }
        //     if (res.status === 404) {
        //         if ('detail' in res.data && res.data.detail == 'Not found.') { 
        //             useNotification(
        //                 'Não encontradas!', 
        //                 'Etiquetas não encontradas.', 
        //                 'danger'
        //             );
        //         }
        //     }
        //     return;
        // })
        // .catch((err) => {
        //     console.error(err);
        // });
    }

    shouldRefresh = () => {
        if ((this.shouldShowReactivationMessage() && !this.isPendingTab()) && this.isActiveChatOnChat())
            return true
        return false
    }

    onCallEvent = (data, fn) => {
        let ev_body = JSON.parse(data.body)[0];

        var eventNewcall = (ev_body, message_id) => {
            if (ev_body.direction === 'OUTBOUND') {
                console.log("EVENT OUTBOUND", this.props.activeChat);
            }
        }

        var eventHangup = (ev_body) => {
            if (ev_body.direction !== 'NO ANSWER' && this.props.activeChat && 'chatstatus' in this.props.activeChat && this.props.activeChat['chatstatus'] === 'ONCHAT' && this.props.activeChat.platform !== 'EMAIL') {
                if (window.setLink) {
                    try{
                        const accountcode = this.props.accountcode
                        const date = new Date();
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const formatedDate = `${year}${month}${day}`;
                        let u64 = '';
                        const uniqueid = ev_body.uniqueid.toString();
                        const destination = this.state.dialNumber.toString();
                        u64 = btoa(uniqueid + ';' + destination)
                        let url = `https://${location.host}/api/api/whatsapp/outbound/${accountcode}/${formatedDate}/${u64}/`;
                        this.postMessage("[audio]" + url + "[/audio]", 0);
                    }catch(e){
                        console.log('error: ',e)
                    }
                    window.setLink = false;
                }
            }
        }

        switch (ev_body.type) {
            case 'NEWCALL':
                eventNewcall(ev_body, data.headers['message-id']);
                break;
            case 'HANGUP':
                eventHangup(ev_body);
                break;
        }

    }

    notifyNoPreferredInterface = () => {
        useNotification(
            'Ramal não configurado',
            'Você precisa ter um ramal configurado para usar essa funcionalidade..',
            'warning'
        );
    }

    render() {
        const contactNick = this.setContactNick();
        const agentName = this.setAgentName();
        const preferred_interface = getSession().profile.agent.preferred_interface;

        const groups = getSession().groups.map(group => group.name)
        const isSupervisor = (groups.includes('supervisor') || groups.includes('administrador'))

        const chatStatus = this.props.activeChat.chatstatus;
        const supervisedMessages = this.props.activeChat.supervised_messages;

        const enterprise = getSession().profile.enterprise;

        return (
            <Box className="chatWindow-box-1">
                {this.state.showContactCreate &&
                    <ClientDialog open={this.state.showContactCreate}
                        title="Novo cliente"
                        type="create"
                        model={this.state.userModel}
                        key={'ClientCreate'}
                        buttonText="Adicionar"
                        handleClose={() => this.setState({ showContactCreate: false })}
                        submit={this.insertContactChatweb}
                        isAdmin={true}
                        listOfUsers={[]}
                    />
                }
                <PredefinedMessageDialog open={this.state.showPredefinedMessages}
                    title="Mensagens Predefinidas"
                    predefinedMessages={this.props.predefinedMessages}
                    addPredefinedMessage={this.props.addPredefinedMessage}
                    updatePredefinedMessage={this.props.updatePredefinedMessage}
                    removePredefinedMessage={this.props.removePredefinedMessage}
                    usePredefinedMessage={this.usePredefinedMessage}
                    handleClose={() => this.setState({ showPredefinedMessages: false })}
                />
                {this.state.showConfirmationDialog &&
                    <HangupDialog open={this.state.showConfirmationDialog}
                        title="Encerrar atendimento"
                        activeChat={this.props.activeChat}
                        description="Tem certeza que deseja finalizar esse atendimento?"
                        handleClose={() => this.setState({ showConfirmationDialog: false })}
                        handleSubmit={this.finishChat}
                    />
                }
                {this.state.showTransferDialog &&
                    <TransferDialog open={this.state.showTransferDialog}
                        title="Transferir atendimento"
                        enterpriseId={this.props.enterpriseId}
                        queues={this.props.enterpriseQueues}
                        queueId={this.props.queueId}
                        queueName={this.props.queueName}
                        transferChat={this.transferChat}
                        error={this.state.transferError}
                        handleClose={() => this.setState({
                            showTransferDialog: false,
                            transferChatButtonLoading: false
                        })}
                        submitButtonLoading={this.state.transferChatButtonLoading}
                    />
                }
                {this.state.showReactivationDialog && 
                    <SendTemplateDialog open={this.state.showReactivationDialog}
                        title="Reativar atendimento"
                        type="reactivation"
                        templates={this.props.templates}
                        activeChat={this.props.activeChat}
                        handleClose={() => this.setState({ showReactivationDialog: false })}
                        finishReactivation={this.finishReactivation}
                        connections={this.props.connections}
                        connectActiveChat={this.connectActiveChat}
                    />
                }
                {this.state.showNotificationDialog &&
                    <SendTemplateDialog open={this.state.showNotificationDialog}
                        title="Reenviar notificação"
                        type="renotification"
                        templates={this.props.templates}
                        activeChat={this.props.activeChat}
                        handleClose={() => this.setState({ showNotificationDialog: false })}
                        finishReactivation={this.finishReactivation}
                        notification_pk={this.props.activeChat.uniqueid}
                        enterpriseId={this.props.enterpriseId}
                        connections={this.props.connections}
                        accountcode={this.props.accountcode}
                    />
                }
                <Box className="chatWindow-box-2">
                    <Box className="chatWindow-box-3">
                        <Box onClick={() => { this.showProfile(); }}>
                            <Tooltip title="Abrir informações do contato" placement="bottom" disableInteractive>
                                {contactNick ?
                                    <Box className="chatWindow-box-4">
                                        {isMobile() &&
                                            <ArrowBack style={{ marginRight: "15px" }} onClick={() => this.props.setActiveChat({})} />
                                        }
                                        <AvatarName
                                            name={contactNick}
                                            size="small"
                                        />
                                        <Typography
                                            text={contactNick}
                                            color="dark-gray"
                                            noWrap={true}
                                            fontWeight="400"
                                        />
                                    </Box>
                                    :
                                    <Box className="chatWindow-box-4">
                                        {isMobile() &&
                                            <ArrowBack style={{ marginRight: "15px" }} onClick={() => this.props.setActiveChat({})} />
                                        }
                                        <PhoneIcon className="headerIcon small" />
                                        <Typography
                                            text={this.props.activeChat.callerid}
                                            color="dark-gray"
                                            noWrap={true}
                                            fontWeight="400"
                                        />
                                    </Box>
                                }
                            </Tooltip>
                        </Box>
                        <Tooltip title="Copiar dados do contato" placement="bottom" disableInteractive>
                            <IconButton onClick={this.copyDataContact}
                                size="large"
                                className="push-left">
                                <CopyIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                        {!isMobile() && enterprise.has_voice &&
                            <Tooltip title={preferred_interface ? "Ligar" : "Ramal não configurado"} interactive>
                                <IconButton onClick={preferred_interface ? this.callContact : this.notifyNoPreferredInterface}
                                    size="large">
                                    <PhoneCallIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        }
                        {chatStatus !== 'QUEUE' && isSupervisor && agentName && !isMobile() &&
                            <Box className="agent-name">
                                <Tooltip title="Operador" placement="bottom" disableInteractive>
                                    <Box className="chatWindow-box-4">
                                        <AgentIcon
                                            className="agent-icon"
                                        />
                                        <Typography
                                            text={agentName}
                                            color="dark-gray"
                                            noWrap={true}
                                            fontWeight="400"
                                        />
                                    </Box>
                                </Tooltip>
                            </Box>
                        }
                    </Box>
                    {/* </Box> */}
                    <Box className="chatWindow-box-5">
                        {(chatStatus === "ONCHAT" || chatStatus === "QUEUE") &&
                            <Tooltip title="Transferir" interactive>
                                <IconButton onClick={() => this.transferDialog()} size="large">
                                    <TransferIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        }
                        {(chatStatus === "ONCHAT" || chatStatus === "QUEUE") &&
                            <Tooltip title="Encerrar" interactive>
                                <IconButton
                                    onClick={() => this.setState({ showConfirmationDialog: true })}
                                    size="large"
                                    className="push-right">
                                    <CloseChatIcon color="error" />
                                </IconButton>
                            </Tooltip>
                        }
                        {this.props.isManageCalls &&
                            <Tooltip title="Fechar" interactive>
                                <IconButton
                                    onClick={() => this.props.closeModal()}
                                    size="large"
                                    className="push-right">
                                    <CloseIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                </Box>
                <Divider />
                {getSession().profile.enterprise.infinite_scroll ?
                    <NewMessageArea
                        innerRef={
                            instance => {
                                this.messageArea = instance;
                            }
                        }
                        chat={this.props.activeChat}
                        messageArea={this.invokeMessageArea}
                        messages={this.props.activeChat.messages}
                        accountcode={this.props.accountcode}
                        supervisedMessages={supervisedMessages}
                        updateMessages={() => { }}
                        enterpriseId={this.props.enterpriseId}
                        chatStatus={chatStatus}
                        isManageCalls={this.props.isManageCalls}
                        preview={this.state.file.preview}
                        setReplying={(message) => this.setState({ replying: message })}
                    /> :
                    <MessageArea
                        ref={
                            instance => {
                                this.messageArea = instance;
                            }
                        }
                        messageArea={this.invokeMessageArea}
                        messages={this.props.activeChat.messages}
                        accountcode={this.props.accountcode}
                        supervisedMessages={supervisedMessages}
                        updateMessages={() => { }}
                        enterpriseId={this.props.enterpriseId}
                        chatStatus={chatStatus}
                        isManageCalls={this.props.isManageCalls}
                        preview={this.state.file.preview}
                        setReplying={(message) => this.setState({ replying: message })}
                    />
                }
                {this.state.file.preview !== undefined &&
                    <Box>
                        <AttachmentArea activeChat={this.props.activeChat}
                            file={this.state.file}
                            setFile={this.setFile}
                            isActiveChatOnQueue={this.isActiveChatOnQueue}
                            connectActiveChat={this.connectActiveChat}
                            chat={this.props.activeChat}
                            addMessage={this.handleAddMessage}
                            connections={this.props.connections}
                            scrollDown={() => this.messageArea?.dragScrollDownEffect()}
                        />
                    </Box>
                }
                {(this.shouldShowFooter() && !this.isPendingTab()) &&
                    <Footer
                        ref={
                            instance => {
                                this.footer = instance;
                            }
                        }
                        sendMessage={this.sendMessage}
                        showPredefinedMessages={this.showPredefinedMessages}
                        setFile={this.setFile}
                        activeChat={this.props.activeChat}
                        isActiveChatOnQueue={this.isActiveChatOnQueue}
                        connectActiveChat={this.connectActiveChat}
                        addMessage={this.handleAddMessage}
                        connections={this.props.connections}
                        scrollDown={() => this.messageArea?.dragScrollDownEffect()}
                        replying={this.state.replying}
                        setReplying={(message) => this.setState({ replying: message })}
                    />
                }
                {(this.state.chat_blocked && this.props.activeChat?.socket) &&
                    <Alert severity="info" className="footer-msg">
                        Aguardando conexão
                    </Alert>}
                {this.isEmailChat() && !this.isActiveChatOnQueue() &&
                    <EmailFooter
                        sendMessage={this.sendMessage}
                    />
                }
                {this.props.isManageCalls && this.isEmailChat() && this.isActiveChatOnQueue() &&
                    <Alert severity="info" className="email-footer-msg">
                        <>
                            <Button
                                variant={'contained'}
                                sx={{ margin: '0.5rem' }}
                                onClick={() => this.connectActiveChat()}
                            >
                                Iniciar Atendimento
                            </Button>
                        </>
                    </Alert>
                }
                {this.isActiveChatBot() &&
                    <Alert severity="info" className="footer-msg">
                        {this.props.activeChat.messages ?
                            <>
                                Aguarde o cliente ser direcionado pelo bot. Alternativamente você pode
                                {!this.isChatWeb() &&
                                    <>
                                        <Button
                                            variant={'contained'}
                                            sx={{ margin: '0.5rem' }}
                                            onClick={() => this.transferDialog()}
                                        >Transferir</Button>
                                        ou
                                    </>
                                }
                            </>
                            :
                            <>Chat não encontrado.</>
                        }
                        <Button
                            variant={'contained'}
                            color={'error'}
                            sx={{ margin: '0.5rem' }}
                            onClick={() => this.setState({ showConfirmationDialog: true })}
                        >Finalizar</Button> esse chat.
                    </Alert>
                }
                {(this.shouldShowReactivationMessage() && !this.isPendingTab()) && this.isActiveChatOnChat() &&
                    <Alert severity="info" className="footer-msg">
                        Aguarde a resposta do cliente para enviar mensagens para este chat novamente
                    </Alert>
                }
                {(this.shouldShowReactivationMessage() && !this.isPendingTab()) && !this.isActiveChatOnChat() &&
                    <Alert severity="warning" className="footer-msg">
                        Este chat só pode ser reativado através de um template pré-aprovado
                        <Button
                            onClick={() => this.setState({ showReactivationDialog: true })}
                            className="alert-btn"
                        >
                            Reativar
                        </Button>
                    </Alert>
                }
                {((this.shouldShowPendingFooter() && !this.props.activeChat.notification) || this.isPendingTab()) &&
                    <>
                        <Alert severity="info" className="footer-msg">
                            Chat reativado, aguardando resposta do cliente.
                            {this.isPendingTab() && isSupervisor &&
                                <> Alternativamente
                                    <Button
                                        variant={'contained'}
                                        color={'error'}
                                        sx={{ margin: '0.5rem' }}
                                        onClick={() => this.setState({ showConfirmationDialog: true })}
                                    >finalize
                                    </Button> esse chat.
                                </>}
                        </Alert>
                    </>
                }
                {this.shouldShowPendingFooter() && this.props.activeChat.notification && !this.isPendingTab() &&
                    <Alert severity="info" className="footer-msg">
                        Cliente notificado, aguardando resposta
                        {this.props.activeChat.notification_origin === 'WHATSAPP' ? <Button
                            onClick={() => this.setState({ showNotificationDialog: true })}
                            className="alert-btn"
                        >
                            Reenviar notificação
                        </Button>:
                        <Button>
                            Não é possível renotificar SMS
                        </Button>
                        }
                    </Alert>
                }
                {this.state.chatWantsReactivate &&
                    <Alert severity="info" className="footer-msg">
                        Chat reativando, aguarde um momento
                    </Alert>
                }
                {this.shouldShowNotReactivatableFooter() &&
                    <Alert severity="info" className="footer-msg">
                        Este chat não pode ser reativado
                    </Alert>
                }
            </Box>
        );
    }
}


export default ChatWindow;
