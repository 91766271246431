import React, { useEffect, useState } from "react"

import { 
    Box, 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    TextField, 
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    MenuList,
    ListItemText,
    Chip
} from "@mui/material"

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { 
    DataGridPro, 
    GridColDef, 
    GridRowsProp, 
    DataGridProProps, 
    GridToolbarContainer, 
    GridToolbarColumnsButton, 
    GridToolbarFilterButton, 
    gridClasses, 
    ptBR 
} from "@mui/x-data-grid-pro"

import { ptBR as corePtBR } from "@mui/material/locale"

import LoadingButton from "@mui/lab/LoadingButton"

import EditIcon from "@mui/icons-material/Edit"
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import InfoIcon from "@mui/icons-material/Info"

import useNotification from "../Notification"
import ConfirmationDialogCaptcha from "../Dialogs/ConfimationDialogCaptcha"

import Typography from "../Typography"
import api from "../../api"

import "./styles.css"
import { NumericFormatInput } from "../../utils/masks";

const EditPlanComponent = ({ plan, setPlan, updatePlan, createPlan }) => {

    const [planProducts, setPlanProducts] = useState([])
    const [products, setProducts] = useState([])

    const removePlanProduct = async (relationId) => {
        setPlanProducts((prevState) => prevState.filter((item) => item.id !== relationId))
    }

    const addPlanProduct = async (productId) => {
        const product = products.find((item) => item.id === productId)
        setPlanProducts((prevState) => {
            const exists = prevState.some((item) => item.id === product.id)
            if (exists)
                return prevState
            return [...prevState, product]
        });
    }

    const getPlanProducts = async () => {
        if ("id" in plan && plan.id) {
            const res = await api.get(`/api/plans/${plan.id}/`)
            setPlanProducts(res.data.products.map((item) => item.product.id))
        }
    }

    const getProducts = async () => {
        const res = await api.get("/api/products/")
        setProducts(res.data)
    }

    useEffect(() => {
        getProducts()
        getPlanProducts()
    }, [])

    return (
        <Dialog fullWidth maxWidth="sm" open={plan !== null} onClose={() => setPlan(null)}>
            <DialogTitle>{(plan.id) ? "Editar plano" : "Adicionar plano"}</DialogTitle>
            <Divider/>
            <DialogContent style={{ paddingTop: "2rem" }}>
              {plan && (
                <Box component="form">
                    <TextField
                        label="Nome"
                        value={plan.name}
                        onChange={(event) => setPlan((prevState) => ({
                            ...prevState, 
                            name: event.target.value
                        }))}
                        fullWidth
                        size="small"
                    />
                    {plan.id &&
                        <Box sx={{ marginTop: "1rem" }}>
                            <FormControl fullWidth size="small" sx={{ marginBottom: "1rem" }}>
                                <InputLabel id="product-selection">Produto(s)</InputLabel>
                                <Select
                                    multiple={true}
                                    labelId="product-selection-label"
                                    id="product-selection"
                                    displayEmpty="Escolha um produto para incluir no plano"
                                    label="Produto(s)"
                                    value={planProducts}
                                    onChange={(event) => setPlanProducts(event.target.value)}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map(value => products.find(product=>product.id === value))
                                            .sort((a, b) => `${a.channel}: ${a.name}`.localeCompare(`${b.channel}: ${b.name}`))
                                            .map((product) => (
                                                <Chip size='small' key={`plan${product.id}`} label={`${product.channel}: ${product.name}`} 
                                                deleteIcon={
                                                    <CancelIcon
                                                        onMouseDown={(event) => event.stopPropagation()}
                                                    />}
                                                onDelete={() => setPlanProducts(planProducts.filter(each => each !== product.id))}
                                                />
                                            ))}
                                        </Box>)}
                                >
                                    {products.filter(product => !planProducts.includes(product.id)).sort((a, b) => `${a.channel}: ${a.name}`.localeCompare(`${b.channel}: ${b.name}`)).map((item) => (
                                        <MenuItem value={item.id}>{`${item.channel}: ${item.name}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <Typography text='Produtos' variant="h6" color="blue"/>
                            <Divider/>
                            {planProducts.length !== 0 &&
                                <Box sx={{ overflow: "auto", maxHeight: "30rem" }}>
                                    <MenuList>
                                        {planProducts.sort((a, b) => `${a.channel}: ${a.name}`.localeCompare(`${b.channel}: ${b.name}`)).map((item) => (
                                            <MenuItem>
                                                <ListItemText onClick={() => removePlanProduct(item.id)}>{`${item.channel}: ${item.name}`}</ListItemText>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </Box>
                            }
                            {planProducts.length === 0 &&
                                <Box sx={{ marginTop: "1rem" }}>
                                    Este plano ainda não possui nenhum produto.
                                </Box>
                            } */}
                        </Box>
                    }
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPlan(null)}>Cancelar</Button>
              <Button onClick={() => (plan.id) ? updatePlan(plan, planProducts) : createPlan(plan)}>Salvar</Button>
            </DialogActions>
          </Dialog>
    )
}

const Plans = () => {

    const [plans, setPlans] = useState([])
    const [plan, setPlan] = useState()
    const [product, setProduct] = useState()
    const [toDeletePlan, setToDeletePlan] = useState()

    const theme = createTheme({
        palette: {
            primary: { main: "#21446C" },
        },
    }, ptBR, corePtBR)

    const updatePlan = (plan, products) => {
        api.patch(`/api/plans/${plan.id}/`, {
            name     : plan.name,
            products : products
        }).then((res) => {
            if (res.status === 200) {
                setPlan(null)
                getPlans()
                useNotification(
                    "Sucesso!",
                    "Plano atualizado com sucesso.",
                    "success"
                )
                return
            }
            throw res
        }).catch((err) => {
            if (err.status === 400 && err.data === "Plan is related to an enterprise.") {
                useNotification(
                    "Atenção",
                    "Você não pode adicionar/retirar produtos de um plano atrelado à uma empresa.",
                    "warning"
                )
                return
            }
            useNotification(
                "Ops!",
                "Não foi possível atualizar o plano, tente novamente.",
                "danger"
            )
        })
    }

    const recalculatePlan = (product) => {
        const parentId = product.id.split("::")[0]

        let planActivation = 0
        let planMonthlyCost = 0
        let planActivationPercentual = 0
        let planMonthlyPercentual = 0

        plans.forEach((plan) => {
            if (String(plan.id).includes("::") && String(plan.id).split("::")[0] === parentId) {
                let obj = (plan.id === product.id) ? product : plan
                if (obj.percentual_values) {
                    planActivationPercentual += parseFloat(obj.activation_value)
                    planMonthlyPercentual += parseFloat(obj.monthly_value)
                } else {
                    planActivation += parseFloat(obj.activation_value)
                    planMonthlyCost += parseFloat(obj.monthly_value)
                }
            }
        })

        planActivation += (planActivation * planActivationPercentual) / 100
        planMonthlyCost += (planMonthlyCost * planMonthlyPercentual) / 100

        setPlans((prevPlans) => prevPlans.map((item) => (item.id == parentId)
            ? {
                ...item,
                activation_value : planActivation / .95,
                monthly_value    : planMonthlyCost / .95
            }
            : item
        ))
    }

    const updateProduct = (product) => {
        const productId = product.id.split("::").pop()
        api.patch(`/api/plans/products/${productId}/`, {
            activation_value  : parseFloat(product.activation_value),
            monthly_value     : parseFloat(product.monthly_value),
            percentual_values : product.percentual_values,
            quantity          : product.quantity
        }).then((res) => {
            if (res.status === 200) {
                setPlans((prevPlans) => prevPlans.map((item) => (item.id === product.id) 
                    ? { 
                        ...item, 
                        activation_value  : parseFloat(product.activation_value), 
                        monthly_value     : parseFloat(product.monthly_value), 
                        percentual_values : product.percentual_values, 
                        quantity          : product.quantity
                    } 
                    : item
                ))
                recalculatePlan(product)
                setProduct(null)
                useNotification(
                    "Sucesso!",
                    "Produto atualizado com sucesso.",
                    "success"
                )
                return
            }
            throw res
        }).catch((err) => {
            useNotification(
                "Ops!",
                "Não foi possível atualizar o produto, tente novamente.",
                "danger"
            )
        })
    }

    const deletePlan = (plan) => {
        api.delete(`/api/plans/${plan.id}/`).then((res) => {
            if (res.status === 204) {
                setPlans((prevPlans) => prevPlans.filter((item) => item.hierarchy[0] !== plan.name))
                setToDeletePlan(null)
                useNotification(
                    "Sucesso!",
                    "Plano removido com sucesso.",
                    "success"
                )
                return
            }
            throw res
        }).catch((err) => {
            if (err.status === 400 && err.data === "Plan is related to an enterprise.") {
                useNotification(
                    "Atenção",
                    "Você não pode remover um plano que está atrelado à uma empresa.",
                    "warning"
                )
                return
            }
            useNotification(
                "Ops!",
                "Não foi possível remover o plano, tente novamente.",
                "danger"
            )
        })
    }

    const duplicatePlan = (plan) => {
        setPlan({ ...plan, id: null, name: `Cópia de ${plan.name}`, copy_from: plan.id })
    }

    const formatCurrency = (value) => new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value)
    
    const formatPercentual = (value) => `${value}%`

    const columns: GridColDef[] = [
        { field: "enterprise_set", headerName: "Empresas", flex: .5 },
        { field: "quantity", headerName: "Quantidade", flex: .5 },
        { field: "suggested_price", headerName: "Preço Sugerido", flex: .5,
            renderCell: (params) => (params.row.hierarchy.length != 1) 
                ? new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format((params.row.quantity > 0) ? params.row.suggested_price * params.row.quantity : params.row.suggested_price)
                : ""
        },
        { field: "activation_value", headerName: "Ativação", flex: .5,
            renderCell: (params) => {
                const value = (params.row.percentual_values)
                    ? formatPercentual(params.row.activation_value)
                    : formatCurrency(params.row.activation_value)
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", gap: .5 }}>
                        {value}
                        {params.row.hierarchy.length == 1 &&
                            <Tooltip title="Total de ativação +5% de ISS" placement="right" disableInteractive>
                                <InfoIcon sx={{ color: "#5399db", fontSize: "1.25em", cursor: "pointer" }}/>
                            </Tooltip>
                        }
                    </Box>
                )
            }
        },
        { field: "monthly_value", headerName: "Mensalidade", flex: .5,
            renderCell: (params) => {
                const value = (params.row.percentual_values)
                    ? formatPercentual(params.row.monthly_value)
                    : formatCurrency(params.row.monthly_value)
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", gap: .5 }}>
                        {value}
                        {params.row.hierarchy.length == 1 &&
                            <Tooltip title="Total de mensalidades +5% de ISS" placement="right" disableInteractive>
                                <InfoIcon sx={{ color: "#5399db", fontSize: "1.25em", cursor: "pointer" }}/>
                            </Tooltip>
                        }
                    </Box>
                )
            }
        },
        { field: "action", headerName: "Ações", flex: .25,
            renderCell: (params) => (
                <Box>
                    <Button className="action-button"
                        onClick={() => (params.row.hierarchy.length == 1)
                            ? setPlan(params.row)
                            : setProduct(params.row)       
                        }>
                        <EditIcon />
                    </Button>
                    {params.row.hierarchy.length == 1 &&
                        <>
                            <Button className="action-button"
                                onClick={() => duplicatePlan(params.row)}>
                                <ContentCopyIcon sx={{ fontSize: "1.3rem" }}/>
                            </Button>
                            <Button className="action-button delete"
                                onClick={() => setToDeletePlan(params.row)}>
                                <DeleteIcon />
                            </Button>
                        </>
                    }
                </Box>
            )
        }
    ];

    const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.hierarchy

    const createPlan = (plan) => {
        if (plan.copy_from) {
            api.post("/api/plans/duplicate/", { name: plan.name, copy_from: plan.copy_from }).then((res) => {
                if (res.status === 201) {
                    setPlan(null)
                    getPlans()
                    useNotification(
                        "Sucesso!",
                        "Plano copiado com sucesso.",
                        "success"
                    )
                    return
                }
                throw res
            }).catch((err) => {
                useNotification(
                    "Ops!",
                    "Não foi possível copiar o plano, tente novamente.",
                    "danger"
                )
            })
        } else {
            api.post("/api/plans/", { name: plan.name }).then((res) => {
                if (res.status === 201) {
                    setPlan(null)
                    getPlans()
                    useNotification(
                        "Sucesso!",
                        "Plano adicionado com sucesso.",
                        "success"
                    )
                    return
                }
                throw err
            }).catch((err) => {
                useNotification(
                    "Ops!",
                    "Não foi possível adicionar o plano, tente novamente.",
                    "danger"
                )
            })   
        }
    }

    const getPlans = () => {
        api.get("/api/plans/").then((res) => {
            if (res.status === 200) {
                const plansRows = res.data.flatMap((plan) => {
                    let planActivation = 0
                    let planMonthlyCost = 0
                    let planActivationPercentual = 0
                    let planMonthlyPercentual = 0

                    const productRows = plan.products.map(({ id, product, activation_value, monthly_value, percentual_values, quantity }) => {
                        const productName = `${product.channel}: ${product.name}`
                        
                        if (percentual_values) {
                            planActivationPercentual += activation_value
                            planMonthlyPercentual += monthly_value
                        } else {
                            planActivation += activation_value
                            planMonthlyCost += monthly_value
                        }

                        return {
                            id: `${plan.id}::${id}`,
                            hierarchy: [plan.name, productName],
                            name: productName,
                            activation_value: activation_value,
                            monthly_value: monthly_value,
                            percentual_values: percentual_values,
                            suggested_price: product.suggested_price,
                            quantity: quantity
                        }
                    })

                    planActivation += (planActivation * planActivationPercentual) / 100
                    planMonthlyCost += (planMonthlyCost * planActivationPercentual) / 100

                    const planRow = {
                        id: plan.id,
                        hierarchy: [plan.name],
                        name: plan.name,
                        enterprise_set: plan.enterprise_set,
                        activation_value: planActivation / .95,
                        monthly_value: planMonthlyCost / .95,
                    }

                    return [...productRows.sort((a, b) => a.name.localeCompare(b.name)), planRow]
                })

                setPlans(plansRows)
            }
            throw res
        }).catch((err) => {})
    }

    useEffect(() => {
        getPlans()
    }, [])

    const CustomToolbar = () => (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <Box className='toolbarLeft'>
                <GridToolbarColumnsButton size="small" />
                <GridToolbarFilterButton size="small" className={'filterControl'} />
                <LoadingButton size="small" component="label" startIcon={<AddCircleIcon/>}
                    onClick={() => {
                        setPlan({ name: "" })
                    }}
                >
                    Adicionar
                </LoadingButton>
            </Box>
        </GridToolbarContainer>
    )
  
    return (
        <Box className="plans-container">
            {toDeletePlan &&
                <ConfirmationDialogCaptcha open={toDeletePlan}
                    title="Remover plano"
                    description={
                        <span>
                            Para remover o plano {toDeletePlan.name}, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.
                        </span>
                    }
                    handleClose={() => setToDeletePlan(null)}
                    handleSubmit={() => deletePlan(toDeletePlan)}
                    captcha={'remover'}
                    alternateNo="Cancelar"
                    alternateYes="Confirmar"
                    alternateName=""
                />
            }
            {plan &&
                <EditPlanComponent
                    plan={plan}
                    setPlan={setPlan}
                    updatePlan={updatePlan}
                    createPlan={createPlan}
                />
            }
            {product &&
                <Dialog fullWidth maxWidth="sm" open={product !== null} onClose={() => setProduct(null)}>
                    <DialogTitle>{`${product.hierarchy[0]} ${product.hierarchy[1]}`}</DialogTitle>
                    <Divider/>
                    <DialogContent style={{ paddingTop: "2rem" }}>
                      {product && (
                        <>
                            <Box component="form" sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel>Tipo</InputLabel>
                                    <Select
                                        value={product.percentual_values}
                                        onChange={(event) => setProduct((prevState) => ({
                                            ...prevState, 
                                            percentual_values: event.target.value
                                        }))}
                                        label="Tipo"
                                    >
                                        <MenuItem value={false}>Número</MenuItem>
                                        <MenuItem value={true}>Percentual</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Quantidade"
                                    value={product.quantity}
                                    onChange={(event) => setProduct((prevState) => ({
                                        ...prevState, 
                                        quantity: event.target.value
                                    }))}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />
                            </Box>
                            <Box component="form" sx={{ display: "flex", flexDirection: "row", gap: 2, marginTop: "1rem" }}>
                                <TextField
                                    label="Ativação"
                                    value={product.activation_value}
                                    onChange={(event) => setProduct((prevState) => ({
                                        ...prevState, 
                                        activation_value: event.target.value
                                    }))}
                                    fullWidth
                                    size="small"
                                    inputProps={{ min: 0 }}
                                    InputProps={{
                                        inputComponent: NumericFormatInput
                                    }}
                                />
                                <TextField
                                    label="Mensalidade"
                                    value={product.monthly_value}
                                    onChange={(event) => setProduct((prevState) => ({
                                        ...prevState, 
                                        monthly_value: event.target.value
                                    }))}
                                    fullWidth
                                    size="small"
                                    inputProps={{ min: 0 }}
                                    InputProps={{
                                        inputComponent: NumericFormatInput
                                    }}
                                />
                            </Box>
                        </>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setProduct(null)}>Cancelar</Button>
                      <Button onClick={() => updateProduct(product)}>Salvar</Button>
                    </DialogActions>
                  </Dialog>
            }
            <ThemeProvider theme={theme}>
                <DataGridPro
                    rows={plans}
                    columns={columns}
                    density="compact"
                    treeData
                    getTreeDataPath={getTreeDataPath}
                    groupingColDef={{
                        headerName: "Plano",
                        flex: 1,
                        //valueGetter: ({row}) => {console.log(row); return `${row.name} (${row.enterprise_set} Empresas)`}
                    }}
                    disableSelectionOnClick
                    pageSize={20}
                    rowsPerPageOptions={[20, 50, 100]}
                    components={{ Toolbar: CustomToolbar }}
                />
            </ThemeProvider>
        </Box>
    );
};

export default Plans
