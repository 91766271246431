import React, { useState } from "react"

import {
    Drawer,
    AppBar,
    Toolbar,
    Tooltip,
    Box,
    IconButton,
    List,
    useTheme,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material"

import "react-notifications-component/dist/theme.css"
import "animate.css/animate.min.css"

import { useHistory } from "react-router-dom"
import { ReactNotifications } from "react-notifications-component"

import PropTypes from "prop-types"
import clsx from "clsx"
import makeStyles from "@mui/styles/makeStyles"

import LogoImage from "../../assets/images/kwik_color.png"
import LogoFavIcon from "../../assets/images/kwik_favicon.png"

import MenuIcon from "@mui/icons-material/Menu"
import InventoryIcon from "@mui/icons-material/Inventory"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CategoryIcon from "@mui/icons-material/Category"
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupsIcon from '@mui/icons-material/Groups';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReportsIcon from '@mui/icons-material/Assessment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EmailIcon from '@mui/icons-material/Email';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';

import api from "../../api"

import "./styles.css"

const AdminDrawer = ({ child }) => {

    const [open, setOpen] = useState(false)

    const drawerWidth = 200

    const theme = useTheme()

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            flexDirection: "column",
            height: "100%"
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: "#5399DB"
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 15,
            marginLeft: -20
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            width: theme.spacing(7)
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 10
        },
        content: {
            width: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
        },
        heading: {
            fontSize: theme.typography.pxToRem(17),
            fontWeight: theme.typography.fontWeightBold,
            color: "#1b426c",
            marginLeft: "1rem",
            marginTop: ".9rem"
        },
    }))

    const classes = useStyles()

    const appClasses = clsx(classes.appBar, { [classes.appBarShift]: open, }) + " app-bar";
    const drawerClasses = clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: ! open, }) + " left-drawer";

    const history = useHistory()

    const selectedPage = (pagename) => {
        if (window.location.pathname === pagename)
            return { sx: {color:"#21446C"}}
    }

    const pages = [
      {
        name: "products",
        description: "Produtos",
        tooltip: "Produtos",
        onClick: () => navigate("/products"),
        icon: <CategoryIcon {...selectedPage("/products")} />,
      },
      {
        name: "plans",
        description: "Planos",
        tooltip: "Planos",
        onClick: () => navigate("/plans"),
        icon: <InventoryIcon {...selectedPage("/plans")} />,
      },
      {
        name: "managers",
        description: "Gerentes",
        tooltip: "Gerentes de Conta",
        onClick: () => navigate("/managers"),
        icon: <GroupsIcon {...selectedPage("/managers")} />,
      },
      {
        name: "enterprises",
        description: "Clientes",
        tooltip: "Clientes",
        onClick: () => navigate("/enterprises"),
        icon: <ApartmentIcon {...selectedPage("/enterprises")} />,
      },
      {
        name: "paymenthistory",
        description: "Cobranças",
        tooltip: "Cobranças",
        onClick: () => navigate("/paymenthistory"),
        icon: <ReportsIcon {...selectedPage("/paymenthistory")} />,
      },
      {
        name: "templates",
        description: "Templates",
        tooltip: "Templates",
        onClick: () => navigate("/templateemail"),
        icon: <EmailIcon {...selectedPage("/templateemail")} />,
      },
      {
        name: "nfse",
        description: "Notas Fiscais",
        tooltip: "Notas Fiscais",
        onClick: () => navigate("/nfse"),
        icon: <ReceiptIcon {...selectedPage("/nfse")} />,
      },
      {
        name: "boletos",
        description: "Boletos",
        tooltip: "Boletos",
        onClick: () => navigate("/boletos"),
        icon: <span style={{rotate: '90deg', marginLeft: '-0.1rem'}}><HorizontalSplitIcon  {...selectedPage("/boletos")} /></span>,
      },
      {
        name: "sequences",
        description: "Configurações",
        tooltip: "Configurações",
        onClick: () => navigate("/sequences"),
        icon: <ReceiptLongIcon {...selectedPage("/sequences")} />,
      }
    ]

    const navigate = (url) => history.push(url)

    return (
        <div className={classes.root}>
            <ReactNotifications />
            <AppBar position="relative" className={appClasses}>
                <Toolbar className="toolbar-body">
                    <Box sx={{ display: "flex" }} flexGrow={1}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(true)}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                        <img src={LogoImage}
                            alt="logo"
                            className="logo"
                            id="logo"
                        />
                        <Typography className={classes.heading}>
                            SuperAdmin
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box className="body-kwik">
                <Drawer variant="permanent" className={drawerClasses}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <img src={LogoFavIcon}
                        alt="logo"
                        className="logoFavIcon"
                        id="logoFavIcon"
                    />
                    <div className={classes.toolbar}>
                        <IconButton onClick={() => setOpen(false)} size="large">
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <List>
                        {pages.map((item, index) => (
                            <Tooltip title={item.tooltip} placement="right" disableInteractive key={index}>
                                <span>
                                <ListItem disabled={item.isDisabled} button key={index} onClick={item.onClick}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText style={{ marginLeft: -15 }}
                                        primary={item.description}
                                    />
                                </ListItem>
                                </span>
                            </Tooltip>
                        ))}
                    </List>
                </Drawer>

                <main style={{ flex: 1 }} className={classes.content}>
                    {child}
                </main>
            </Box>
        </div>
    )
}

AdminDrawer.propTypes = {
    child: PropTypes.any
}

export default AdminDrawer
